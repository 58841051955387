import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ContentContainer, LoaderContainer } from '../styled';
import {
    ApproveAction,
    DndGrid,
    Drawer,
    Heading,
    Loader,
    ModalBody,
} from '../../../components';
import { TopPanel } from './components';
import { useObjectForm, useObjects } from './hooks';
import { useDrawerVisible } from '../../../hooks';
import { editObjectSteps } from './constants/editObjectSteps';
import { GridBodyItem } from './components/GridBodyItem';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { useDrafts } from './hooks/useDrafts';
import { addObjectSteps } from './components/TopPanel/constants/addObjectSteps';
import { Modal } from '@material-ui/core';

interface ObjectsProps extends RouteComponentProps<{ id: string }> {}

export function Objects(props: ObjectsProps) {
    const { id } = props.match.params;
    const [editObjectDrawerIsOpen, setEditObjectDrawerIsOpen] = useState(false);
    const [
        removeObjectModalApproveIsOpen,
        setRemoveObjectModalApproveIsOpen,
    ] = useState({
        state: false,
        objectId: 0,
    });
    const [draftsIsOpened, setDraftsVisibleState] = useState(false);
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const [state, updateFormData, resetFormData] = useObjectForm();
    const { drafts, openDraft, getAllDrafts, removeDraft } = useDrafts();

    const [
        objects,
        objectsIsPending,
        objectsFetched,
        getOrganizationObjects,
        changeObjectsOrder,
        deleteObjectHandle,
    ] = useObjects(Number(id));

    useEffect(() => {
        if (!objectsFetched && !objectsIsPending) {
            getOrganizationObjects();
        }
    }, [getOrganizationObjects, objects, objectsFetched, objectsIsPending]);

    if (objectsIsPending) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    function openEditObject({ object }: any) {
        updateFormData({ ...state, ...object });
        setEditObjectDrawerIsOpen(true);
    }

    function deleteObject() {
        deleteObjectHandle(removeObjectModalApproveIsOpen.objectId);
        closeApproveModal();
    }

    function closeApproveModal() {
        setRemoveObjectModalApproveIsOpen({ state: false, objectId: 0 });
    }

    function openApproveModal({ object }: any) {
        setRemoveObjectModalApproveIsOpen({
            state: true,
            objectId: object.basicData.id,
        });
    }

    function closeAddObject() {
        resetFormData();
        closeDrawer();
    }

    function closeEditObject() {
        resetFormData();
        setEditObjectDrawerIsOpen(false);
    }

    async function openDrafts() {
        await getAllDrafts();
        setDraftsVisibleState(true);
    }

    function closeDrafts() {
        setDraftsVisibleState(false);
    }

    const preparedObject = objects.map((object, index) => {
        const menu = [
            {
                name: 'Edytuj',
                clickHandle: openEditObject,
                args: {
                    object,
                },
            },
            {
                name: 'Usuń',
                clickHandle: openApproveModal,
                args: {
                    object,
                },
            },
        ];

        return {
            key: String(object.basicData.id),
            element: (
                <GridBodyItem
                    menuComponents={menu}
                    key={index}
                    object={object}
                />
            ),
            index,
        };
    });

    function onEndSortHandle(sort: SortEnd) {
        changeObjectsOrder(arrayMove(objects, sort.oldIndex, sort.newIndex));
    }

    function openDraftHandle(id: string) {
        openDraft(id);
        closeDrafts();
        openDrawer();
    }

    return (
        <ContentContainer>
            <Heading color="black">Obiekty</Heading>
            <Modal
                open={removeObjectModalApproveIsOpen.state}
                onClose={closeApproveModal}
            >
                <ModalBody closeModalOnCLick={closeApproveModal}>
                    <ApproveAction
                        desc="Czy na pewno chcesz usunąć ten obiekt?"
                        approveOnClick={deleteObject}
                        cancelOnClick={closeApproveModal}
                    />
                </ModalBody>
            </Modal>
            <TopPanel
                removeDraft={removeDraft}
                drawerSteps={addObjectSteps}
                openDraft={openDraftHandle}
                drafts={drafts}
                openAddObject={openDrawer}
                closeAddObject={closeAddObject}
                isOpen={drawerState}
                openDrafts={openDrafts}
                draftsIsOpened={draftsIsOpened}
                closeDrafts={closeDrafts}
            />
            <Drawer
                approveClose={true}
                data={editObjectSteps}
                hasProgressStepper={true}
                isObjectEdit={true}
                drawerIsVisible={editObjectDrawerIsOpen}
                setDrawerHide={closeEditObject}
            />
            <DndGrid
                onEndSortHandle={onEndSortHandle}
                headings={[
                    'Nazwa',
                    'Nazwa skrócona',
                    'Miasto',
                    'IP',
                    'Szablon',
                    '',
                ]}
                items={preparedObject}
            />
        </ContentContainer>
    );
}
