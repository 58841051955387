import { SchematicForm } from '../types/SchematicForm';
import { Schematic } from '../types/Schematics';

export enum SchematicsActionTypes {
    FetchSchematics = '[Schematics] fetch schematics',
    FetchSchematicsSuccess = '[Schematics] fetch schematics success',
    FetchSchematicsFailed = '[Schematics] fetch schematics failed',

    AddNewSchematic = '[Schematic] add new schematic',
    AddNewSchematicSuccess = '[Schematic] add new schematic success',
    UpdateEditForm = '[Schematic] update edit form',

    EditSchematic = '[Schematic] edit schematic',
    EditSchematicSuccess = '[Schematic] edit schematic success',

    ResetStore = '[Schematic] reset store',
    UpdateOrder = '[Schematic] update order',
}

export interface UpdateEditForm {
    type: typeof SchematicsActionTypes.UpdateEditForm;
    schematic: SchematicForm;
}

export interface EditSchematic {
    type: typeof SchematicsActionTypes.EditSchematic;
}

export interface EditSchematicSuccess {
    type: typeof SchematicsActionTypes.EditSchematicSuccess;
    schematic: Schematic;
}

export interface UpdateOrder {
    type: typeof SchematicsActionTypes.UpdateOrder;
    schematics: Schematic[];
}

export interface ResetStore {
    type: typeof SchematicsActionTypes.ResetStore;
}

export interface FetchSchematics {
    type: typeof SchematicsActionTypes.FetchSchematics;
}

export interface FetchSchematicsSuccess {
    type: typeof SchematicsActionTypes.FetchSchematicsSuccess;
    schematics: Schematic[];
}

export interface FetchSchematicsFailed {
    type: typeof SchematicsActionTypes.FetchSchematicsFailed;
}

export interface AddNewSchematic {
    type: typeof SchematicsActionTypes.AddNewSchematic;
}

export interface AddNewSchematicSuccess {
    type: typeof SchematicsActionTypes.AddNewSchematicSuccess;
    schematic: Schematic;
}

export type SchematicsUnion =
    | FetchSchematics
    | FetchSchematicsSuccess
    | FetchSchematicsFailed
    | AddNewSchematic
    | ResetStore
    | UpdateOrder
    | EditSchematic
    | UpdateEditForm
    | EditSchematicSuccess
    | AddNewSchematicSuccess;
