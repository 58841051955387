// @ts-ignore
import * as Yup from 'yup';

export const BasicDataScheme = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    shortName: Yup.string()
        .max(15, 'Too Long!')
        .required('Required'),
    ipAddress: Yup.string()
        .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
            message: 'Invalid IP address',
            excludeEmptyString: true,
        })
        .test(
            'ipAddress',
            'IP address value should be less or equal to 255',
            (value: string) => {
                if (value === undefined || value.trim() === '') return true;
                return (
                    value.split('.').find(i => parseInt(i) > 255) === undefined
                );
            },
        )
        .required(),
    ipAddressFix: Yup.string()
        .max(15, 'Too Long!')
        .required('Required')
});
