import React, { useState } from 'react';
import { FormikProps } from 'formik';
import {
    NumericSettings,
    Settings,
    TextualSettings,
} from '../../../../../Objects/types';
import { NumericRow, TextualRow } from './components';
import { generateRandomString } from '../../../../../../../tools';
import { Flex } from '../../../../../../../styled/';
import { BottomPanel } from '../../../BottomPanel';
import { HorizontalDivider } from './styled';
import { useUnits } from '../../../../../../../hooks';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { getTempIdOrId } from '../../../tools/getTempIdOrId';
import { Typography } from '@material-ui/core';
import { useNumericConfiguration } from '../../../../hooks/useNumericConfiguration';
import { useTextualConfiguration } from '../../../../hooks/useTextualConfiguration';

interface FieldsProps extends FormikProps<Settings> {
    prevStepHandle: (value: Settings) => void;
    numericErrors: string[];
    textualErrors: string[];
    isEdit: boolean;
    isAddTemplate: boolean;
}

export function Fields(props: FieldsProps) {
    const { fetchedUnitsByTypeId } = useUnits();
    const { numerical } = useNumericConfiguration();
    const { textual } = useTextualConfiguration();
    const [selectedNumericRow, selectNumericRows] = useState<any[]>([]);
    const [selectedTextualRow, selectTextualRows] = useState<any[]>([]);

    function prevHandle() {
        props.prevStepHandle(props.values);
    }

    function selectNumerical(setting: NumericSettings) {
        if (selectedNumericRow.includes(getTempIdOrId(setting))) {
            selectNumericRows(
                selectedNumericRow.filter(
                    filteredValue => filteredValue !== getTempIdOrId(setting),
                ),
            );
            return;
        }
        selectNumericRows([...selectedNumericRow, getTempIdOrId(setting)]);
    }

    function selectTextual(setting: TextualSettings) {
        if (selectedTextualRow.includes(getTempIdOrId(setting))) {
            selectTextualRows(
                selectedTextualRow.filter(
                    filteredValue => filteredValue !== getTempIdOrId(setting),
                ),
            );
            return;
        }
        selectTextualRows([...selectedTextualRow, getTempIdOrId(setting)]);
    }

    function addNewNumerical() {
        props.setValues({
            ...props.values,
            numeric: [
                ...props.values.numeric,
                {
                    tempId: generateRandomString(),
                    name: undefined,
                    value: '',
                    unit: undefined,
                },
            ],
        });
    }

    function addNewTextual() {
        props.setValues({
            ...props.values,
            textual: [
                ...props.values.textual,
                {
                    tempId: generateRandomString(),
                    name: undefined,
                    description: '',
                },
            ],
        });
    }

    function removeTextual() {
        props.setValues({
            ...props.values,
            textual: props.values.textual.filter(
                textualFiltered =>
                    !selectedTextualRow.includes(
                        getTempIdOrId(textualFiltered),
                    ),
            ),
        });
        selectTextualRows([]);
    }

    function removeNumerical() {
        const filteredRows = props.values.numeric.filter(
            numericFiltered =>
                !selectedNumericRow.includes(getTempIdOrId(numericFiltered)),
        );

        props.setValues({
            ...props.values,
            numeric: filteredRows,
        });
        selectNumericRows([]);
    }

    function renderNumerical() {
        return props.values.numeric.map((value, index) => (
            <NumericRow
                numerical={numerical}
                unitsById={fetchedUnitsByTypeId}
                formikProps={props}
                onCheck={selectNumerical}
                isChecked={selectedNumericRow.includes(getTempIdOrId(value))}
                hasError={props.numericErrors.includes(value.tempId)}
                key={index}
                setting={value}
                isEdit={props.isEdit}
            />
        ));
    }

    function renderTextual() {
        return props.values.textual.map((value, index) => (
            <TextualRow
                textual={textual}
                formikProps={props}
                onCheck={selectTextual}
                hasError={props.textualErrors.includes(value.tempId)}
                isChecked={selectedTextualRow.includes(getTempIdOrId(value))}
                key={index}
                isEdit={props.isEdit}
                setting={value}
            />
        ));
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <Flex flexDirection="column">
                <Typography>Informacje tekstowe</Typography>
                {renderTextual()}
                {props.isEdit ? null : (
                    <BottomPanel<null>
                        actionHandleArg={null}
                        primaryButtonActionHandle={addNewTextual}
                        primaryButtonText="Dodaj"
                        secondButtonActionHandle={removeTextual}
                        secondButtonText="Usuń"
                    />
                )}
            </Flex>
            <HorizontalDivider />
            <Flex flexDirection="column">
                <Typography>
                    Wartości liczbowe - do dalszych obliczeń
                </Typography>
                {renderNumerical()}
                {props.isEdit ? null : (
                    <BottomPanel<null>
                        actionHandleArg={null}
                        primaryButtonActionHandle={addNewNumerical}
                        primaryButtonText="Dodaj"
                        secondButtonActionHandle={removeNumerical}
                        secondButtonText="Usuń"
                    />
                )}
            </Flex>
            <BottomNavBar
                prevStep={prevHandle}
                showBack={!props.isAddTemplate}
                buttonNextText={props.isEdit ? 'Edit' : 'Dalej'}
            />
        </form>
    );
}
