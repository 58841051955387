import { Heading } from '../../../../../../../../components/DataGrid/types';

export const headingsKey = ['name', 'type', 'shortDescription'];
export const headings: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
        type: 'string',
    },
    {
        key: 'type',
        heading: 'Typ',
        type: 'string',
    },
    {
        key: 'shortDescription',
        heading: 'Skrócony opis',
        type: 'string',
    },
];
