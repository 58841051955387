import { RouteConfig } from '../../../types/RouteConfig';
import {
    Billing,
    Details,
    LoginAsAdmin,
    Logs,
    Objects,
    Parameters,
    Statistics,
    Users,
    Alarms,
    Groups,
    Templates,
    Schematics,
} from '../../../views';

export const organizationRouting: RouteConfig[] = [
    {
        component: Objects,
        path: '/organization/:id/objects',
    },
    {
        component: Users,
        path: '/organization/:id/users',
    },
    {
        component: Groups,
        path: '/organization/:id/groups',
    },
    {
        component: Billing,
        path: '/organization/:id/billing',
    },
    {
        component: Details,
        path: '/organization/:id/details',
    },
    {
        component: Parameters,
        path: '/organization/:id/parameters',
    },
    {
        component: Templates,
        path: '/organization/:id/templates',
    },
    {
        component: Schematics,
        path: '/organization/:id/schematics',
    },
    {
        component: Logs,
        path: '/organization/:id/logs',
    },
    {
        component: LoginAsAdmin,
        path: '/organization/:id/login-as-admin',
    },
    {
        component: Statistics,
        path: '/organization/:id/statistics',
    },
    {
        component: Alarms,
        path: '/organization/:id/alarms',
    },
];
