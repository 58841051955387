import React from 'react';

import { ClickableBox, SelectBox } from './styled';
import Checkbox from '@material-ui/core/Checkbox';
import {
    FormikCreteableSelect,
    FormikSelect,
    FormikTextField,
} from '../../../../../../../../../tools/FormikFields';
import { PARAMETER_TYPES } from '../../../../../../../Parameters/components/TopPanel/forms/selectData/selectData';
import EditIcon from '@material-ui/icons/Edit';
import {
    Parameter,
    ParameterTypesNames,
} from '../../../../../../../Parameters/types/ParameterTypes';
import { RowWithError } from '../../../../../../../Objects/components/TopPanel/styled';
import { SelectOption } from '../../../../../../../../../types/SelectOption';

interface RowProps {
    parameter: Parameter;
    onRowCheck: (parameter: Parameter) => void;
    setAddress: (parameter: Parameter, value: string) => void;
    setSelectName: (parameter: Parameter, option: any) => void;
    setSelectType: (parameter: Parameter, value: any) => void;
    isChecked: boolean;
    setFormValue: (values: any) => any;
    formValues: Parameter[];
    variables: SelectOption<string>[];
    editVariable: (variable: Parameter) => void;
    hasError: boolean;
}

export function RowComponent(props: RowProps) {
    function onCheckHandle() {
        props.onRowCheck(props.parameter);
    }

    function setSelectType(event: any) {
        props.setSelectType(props.parameter, event.value);
    }

    function setSelectName(option: any) {
        props.setSelectName(props.parameter, option);
    }

    const setAddres = (value: any) => {
        props.setAddress(props.parameter, value);
    };

    function editRow() {
        props.editVariable(props.parameter);
    }

    return (
        <RowWithError hasError={props.hasError}>
            <ClickableBox>
                <Checkbox
                    checked={props.isChecked}
                    onChange={onCheckHandle}
                    value="checkedB"
                    color="primary"
                />
            </ClickableBox>
            <SelectBox>
                <FormikSelect
                    cacheOptions
                    value={{
                        value: props.parameter.type,
                        label: ParameterTypesNames[props.parameter.type],
                    }}
                    options={PARAMETER_TYPES}
                    onChange={setSelectType}
                    placeholder="Typ parametru"
                />
            </SelectBox>
            <SelectBox>
                <FormikCreteableSelect
                    options={props.variables}
                    defaultOptions
                    value={props.parameter.name}
                    onChange={setSelectName}
                    placeholder="Nazwa zmiennej"
                />
            </SelectBox>
            <SelectBox>
                <FormikTextField
                    variant="outlined"
                    value={props.parameter.address}
                    onChange={setAddres}
                    placeholder="Adres"
                />
            </SelectBox>
            <ClickableBox onClick={editRow}>
                <EditIcon />
            </ClickableBox>
        </RowWithError>
    );
}

// export const Row = RowComponent;
// export const Row = React.memo(RowComponent);
export const Row = React.memo(RowComponent, (prev, next) => {
    return (
        prev.isChecked === next.isChecked &&
        prev.parameter.address === next.parameter.address &&
        prev.parameter.type === next.parameter.type &&
        prev.hasError === next.hasError
    );
});
