import React from 'react';
import { FormikProps } from 'formik';
import { WideField } from '../../../../../../../styled/';
import { Button, Typography } from '@material-ui/core';
import { ButtonsBox } from './styled';
import { SchematicForm } from '../../../../types/SchematicForm';
import { DragAndDropZone } from '../../../../../Templates/components/DragAndDropZone';

interface FieldsProps extends FormikProps<SchematicForm> {
    organizationId: number;
    isEdit: boolean;
}

export function Fields(props: FieldsProps) {
    function fileHandle(event: File[]) {
        props.setValues({
            ...props.values,
            file: event[0] ?? props.values.file,
        });
    }
    return (
        <form onSubmit={props.handleSubmit}>
            <WideField
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.name && props.touched.name}
                label="Nazwa schematu"
                variant="outlined"
            />
            <DragAndDropZone
                acceptFiles={['image/svg+xml']}
                changeHandle={fileHandle}
                multiple={true}
                error={props.errors.file && props.touched.file}
            />
            {props.values.file instanceof File
                ? props.values.file.name
                : props.values.file}
            <ButtonsBox>
                <Button
                    onClick={props.submitForm}
                    color="primary"
                    variant="contained"
                >
                    {props.isEdit ? 'Edytuj' : 'Stwórz'}
                </Button>
            </ButtonsBox>
        </form>
    );
}
