import { ApiMethod } from '../types/ApiMethod';
import { getItemFromStorage } from './storage';
import { StorageKeys } from '../constants/storageKeys';
import history from '../containers/App/tools/history';

interface InitRequest {
    method: ApiMethod;
    headers: {
        Authorization: string;
        Accept: string;
        'Content-Type'?: string;
    };
    body: FormData | string | undefined | null;
}

class Request<RT> {
    public requestPromise: Promise<RT>;

    // constructor(method: ApiMethod, url: string, params: string);
    // constructor(
    //     method: ApiMethod,
    //     url: string,
    //     params: string = '',
    //     body: FormData | string | undefined,
    // ) {}
    constructor(
        method: ApiMethod,
        url: string,
        params: string = '',
        body?: FormData | string | undefined,
    ) {
        let initReq: InitRequest = {
            method,
            headers: {
                Authorization: `Bearer  ${getItemFromStorage(
                    StorageKeys.Token,
                )}`,
                Accept: 'application/json',
            },
            body: body || null,
        };

        if (!(body instanceof FormData)) {
            initReq.headers['Content-Type'] = 'application/json';
        }

        this.requestPromise = fetch(
            `${process.env.REACT_APP_API_URL}/${url}/${params}`,
            initReq as RequestInit,
        )
            .then(response => {
                if (response.type === 'cors' && response.status === 500) {
                    throw response.json();
                }
                if (response.status === 401) {
                    history.push('/login');
                    throw response.json();
                }

                if (response.status !== 200) {
                    throw response.json();
                }

                return response.json();
            })
            .catch(async err => {
                const resp = await err;
                throw resp;
            });
    }
}

export function request<RT = any>(
    method: ApiMethod,
    url: string,
    body?: FormData | string,
    params?: string,
): Promise<any> {
    return new Request<RT>(method, url, params, body).requestPromise;
}
