import React, { useState } from 'react';
import { FormikProps } from 'formik';

import { FormProps } from './types';
import { BottomNavBar } from '../../../../../../../../../components/ProgressStepper/components';
import {
    FormikAsyncSelect,
    FormikCheckBox,
} from '../../../../../../../../../tools/FormikFields';
import {
    ObjectWithPermission,
    UserPermission,
} from '../../../../../../types/ObjectsWithPermissions';
import { useFetchAsyncSelectData } from '../../../../../../../Parameters/hooks/useFetchAsyncSelectData';
import { SelectBox } from '../../../../../../../Templates/components/Variables/components/Form/component/Row/styled';
import { Flex } from '../../../../../../../../../styled/';
import { Row } from './components/Row';
import { BottomPanel } from '../../../../../../../Templates/components/BottomPanel';
import { generateRandomString } from '../../../../../../../../../tools';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { MachineObject } from '../../../../../../../Objects/types';
import { CellLabelContainer } from './styled';

interface FieldsProps extends FormikProps<UserPermission>, FormProps {
    organizationId: string;
}

export function Fields(props: FieldsProps) {
    const { fetchUserTypes } = useFetchAsyncSelectData();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    function removeRow() {
        props.setValues({
            ...props.values,
            objects: props.values.objects.filter(
                object => !selectedRows.includes(object.tempId),
            ),
        });
        setSelectedRows([]);
    }

    function selectRow(object: ObjectWithPermission) {
        if (selectedRows.includes(object.tempId)) {
            setSelectedRows(selectedRows.filter(row => row !== object.tempId));
            return;
        }
        setSelectedRows([...selectedRows, object.tempId]);
    }

    function selectObject(
        object: ObjectWithPermission,
        machineObject: MachineObject,
    ) {
        props.setValues({
            ...props.values,
            objects: props.values.objects.map(objectValue => {
                if (objectValue.tempId === object.tempId) {
                    return {
                        ...objectValue,
                        object: machineObject,
                    };
                }
                return objectValue;
            }),
        });
    }

    function addNewRow() {
        props.setValues({
            ...props.values,
            objects: [
                ...props.values.objects,
                {
                    tempId: generateRandomString(),
                    object: undefined,
                    permissions: {
                        dataIsShowing: false,
                        order: false,
                        createAlarms: false,
                    },
                },
            ],
        });
    }

    function setAlarmPermission(object: ObjectWithPermission) {
        props.setValues({
            ...props.values,
            objects: props.values.objects.map(objectValue => {
                if (objectValue.tempId === object.tempId) {
                    return {
                        ...objectValue,
                        permissions: {
                            ...objectValue.permissions,
                            createAlarms: !objectValue.permissions.createAlarms,
                        },
                    };
                }
                return objectValue;
            }),
        });
    }
    function setOrderPermission(object: ObjectWithPermission) {
        props.setValues({
            ...props.values,
            objects: props.values.objects.map(objectValue => {
                if (objectValue.tempId === object.tempId) {
                    return {
                        ...objectValue,
                        permissions: {
                            ...objectValue.permissions,
                            order: !objectValue.permissions.order,
                        },
                    };
                }
                return objectValue;
            }),
        });
    }
    function setDataShowingPermission(object: ObjectWithPermission) {
        props.setValues({
            ...props.values,
            objects: props.values.objects.map(objectValue => {
                if (objectValue.tempId === object.tempId) {
                    return {
                        ...objectValue,
                        permissions: {
                            ...objectValue.permissions,
                            dataIsShowing: !objectValue.permissions
                                .dataIsShowing,
                        },
                    };
                }
                return objectValue;
            }),
        });
    }

    function renderRows() {
        return props.values?.objects?.map((object, index) => (
            <Row
                selectObject={selectObject}
                selectRow={selectRow}
                alarmsCreateIsDisabled={!props.values.permission.alarms}
                orderIsDisabled={!props.values.permission.write}
                setDataShowingPermission={setDataShowingPermission}
                setAlarmPermission={setAlarmPermission}
                setOrderPermission={setOrderPermission}
                objectPermission={object}
                usedObjects={props.values.objects
                    .filter(item => item.object)
                    .map(item => (item.object as any).value)}
                organizationId={props.organizationId}
                isSelected={selectedRows.includes(object.tempId)}
                key={index}
            />
        ));
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <Flex>
                <div style={{ width: 360, marginRight: 15 }}>
                    <SelectBox>
                        <FormikAsyncSelect
                            name="permission"
                            cacheOptions
                            loadOptions={fetchUserTypes}
                            defaultOptions
                            placeholder="Rola użytkownika"
                            onChange={(value: any) => {
                                props.setFieldValue('permission', value);
                                if (value.admin) {
                                    props.setFieldValue('allObjects', true);
                                } else {
                                    props.setFieldValue('allObjects', false);
                                }
                            }}
                            value={props.values.permission}
                        />
                    </SelectBox>
                </div>
                <FormikCheckBox
                    name="allObjects"
                    checked={props.values.allObjects}
                    onChange={() =>
                        props.setFieldValue(
                            'allObjects',
                            !props.values.allObjects,
                        )
                    }
                    label="Wszystkie obiekty"
                />
            </Flex>
            {props.values.allObjects ? null : (
                <>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell style={{ minWidth: 200 }} />
                                <TableCell>
                                    <CellLabelContainer title="Podgląd danych">
                                        <span>Podgląd</span>
                                        <br />
                                        <span>danych</span>
                                    </CellLabelContainer>
                                </TableCell>
                                <TableCell padding="checkbox">
                                    <CellLabelContainer title="Rozkazy / Nastawy">
                                        <span>Rozkazy</span>
                                        <br />
                                        <span>Nastawy</span>
                                    </CellLabelContainer>
                                </TableCell>
                                <TableCell>
                                    <CellLabelContainer title="Tworzenie Alarmów">
                                        <span>Tworzenie</span>
                                        <br />
                                        <span>Alarmów</span>
                                    </CellLabelContainer>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderRows()}</TableBody>
                    </Table>
                    <BottomPanel<null>
                        primaryButtonActionHandle={addNewRow}
                        actionHandleArg={null}
                        primaryButtonText="Dodaj"
                        secondButtonActionHandle={removeRow}
                        secondButtonText="Usuń"
                    />
                </>
            )}
            <BottomNavBar prevStep={props.prevStep} />
        </form>
    );
}
