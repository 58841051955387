import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Fields } from './Fields';
import { useObjectForm, useUpdateObject } from '../../../../../Objects/hooks';
import { validateRow } from '../../../../../../../tools/validateFields';
import { StepProps } from '../../../../types/StepProps';
import { Settings } from '../../../../../Objects/types';

interface FormProps extends StepProps {
    closeDrawer: () => void;
}

export function Form(props: FormProps) {
    const [state, setData] = useObjectForm();
    const [, , , update] = useUpdateObject();
    const initErrors = {
        numericErrors: [],
        textualErrors: [],
    };
    const [errors, setErrors] = useState<{
        numericErrors: string[];
        textualErrors: string[];
    }>(initErrors);

    function prevHandle(value: Settings) {
        setData({ ...state, settings: value });
        props.prevStep();
    }

    const onSubmitHandle = (value: any) => {
        setErrors(initErrors);
        const numericErrors = validateRow(value.numeric, [
            'name',
            'value',
            'unit',
        ]);
        const textualErrors = validateRow(value.textual, [
            'name',
            'description',
        ]);

        if (numericErrors.length || textualErrors.length) {
            setErrors({
                numericErrors,
                textualErrors,
            });
            return;
        }

        if (props.isObjectEdit) {
            update({
                basicData: state.basicData,
                localization: state.localization,
                settings: value,
                photoGalleries: state.photoGalleries,
                documents: state.documents,
                removedDocuments: state.removedDocuments ?? [],
                removedGalleries: state.removedGalleries ?? [],
            });
            return;
        }
        setData({ ...state, settings: value });
        props.nextStep();
    };

    return (
        <Formik
            onSubmit={onSubmitHandle}
            enableReinitialize={true}
            initialValues={state.settings}
            render={(otherProps: FormikProps<any>) => (
                <Fields
                    numericErrors={errors.numericErrors}
                    textualErrors={errors.textualErrors}
                    prevStepHandle={prevHandle}
                    isEdit={props.isObjectEdit}
                    isAddTemplate={props.isAddTemplate}
                    {...otherProps}
                />
            )}
        />
    );
}
