import React, { useContext } from 'react';
import { Form } from './components';
import { StepProps } from '../../types/StepProps';
import {
    useObjectForm,
    useObjects,
    useUpdateObject,
} from '../../../Objects/hooks';
import { NotificationHandlersContext } from '../../../../../containers/App/components/AppWithMenu/AppWithMenu';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface SettingsProps extends StepProps, RouteComponentProps<{ id: string }> {
    closeDrawer: () => void;
}

export function Component(props: SettingsProps) {
    const { id } = props.match.params;
    const [, , resetForm] = useObjectForm();
    const [, success, error] = useUpdateObject();
    const notifyHandlers = useContext(NotificationHandlersContext);
    const [, , , getObjects] = useObjects(Number(id));

    if (success) {
        props.closeDrawer();
        resetForm();
        notifyHandlers.showSuccess('Obiekt został zmodyfikowany');
        getObjects();
    }

    if (error) {
        notifyHandlers.showError(error);
    }

    return <Form {...props} />;
}

export const Settings = withRouter(Component);
