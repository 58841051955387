import { Heading } from '../../../../components/DataGrid/types';

export const tableHeaders: Heading[] = [
    {
        key: 'name',
        heading: 'Nazwa',
        type: 'string',
    },
    {
        key: 'nameShort',
        heading: 'Nazwa skrócona',
        type: 'string',
    },
    {
        key: 'postCode',
        heading: 'Kod pocztowy',
        type: 'string',
    },
    {
        key: 'town',
        heading: 'Miasto',
        type: 'string',
    },
    {
        key: 'show',
        heading: 'Otwórz',
        type: 'string',
    },
];
