import { BasicData } from '../../types';

export const initBasicData: BasicData = {
    name: '',
    organizationId: 0,
    shortName: '',
    template: null,
    ipAddress: '',
    ipAddressFix: '',
    cameraUrl: '',
    useExistingTable: false,
    registerAddressOffset: 0,
    apiMeteoUrlName: '',
    apiMeteoUrlKey: 1,
};
