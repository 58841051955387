import React from 'react';
import { FormikProps } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, FormControlLabel } from '@material-ui/core';

import {
    PhotoGalleryForm,
    Image,
} from '../../../../../Objects/types/PhotoGalleryForm';
import { WideField } from '../../../../../../../styled/';
import { BottomPanel, DragAndDropZone } from '../index';
import {
    ExpansionPanelDetailsWrapper,
    ExpansionPanelSummaryWrapper,
} from './styled';
import { PhotosPanel } from '../PhotosPanel';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';

interface FieldsProps extends FormikProps<PhotoGalleryForm[]> {
    prevStep: () => void;
    removeGalleries: () => void;
    addNewGallery: (formData: PhotoGalleryForm[]) => void;
    checkedList: (string | number)[];
    checkHandle: (galleryName: string | number) => void;
}
export function Fields(props: FieldsProps) {
    function renderGallery() {
        return props.values.map((gallery, index) => {
            const id = (gallery.tempId ?? gallery.galleryId) as string | number;
            const photos = (gallery.galleryPhotos ?? gallery.images) as (
                | File
                | Image
            )[];
            function checkHandle() {
                props.checkHandle(id);
            }

            function nameHandle(name: any) {
                const update = props.values.map(editedGallery => {
                    if (editedGallery.tempId === gallery.tempId) {
                        return {
                            ...editedGallery,
                            galleryName: name.target.value,
                        };
                    }
                    return editedGallery;
                });
                props.setValues(update);
            }

            function removePhoto(index: number) {
                const galleryId = gallery.tempId ?? gallery.galleryId;
                const photo = photos[index] as any;

                const update = props.values.map(editedGallery => {
                    if (
                        (editedGallery.tempId ?? editedGallery.galleryId) ===
                        galleryId
                    ) {
                        const removedPhotos: number[] =
                            editedGallery.removedPhotos ?? [];
                        if (photo.id) {
                            removedPhotos.push(photo.id);
                        }
                        delete editedGallery.images;
                        photos.splice(index, 1);
                        return {
                            ...editedGallery,
                            galleryPhotos: photos,
                            removedPhotos,
                        };
                    }
                    return editedGallery;
                });
                props.setValues(update);
            }

            function photoHandle(files: File[]) {
                const update = props.values.map(editedGallery => {
                    const galleryId =
                        editedGallery.tempId ?? editedGallery.galleryId;
                    if (galleryId === id) {
                        return {
                            ...editedGallery,
                            galleryPhotos: [...photos, ...files],
                        };
                    }
                    return editedGallery;
                });
                props.setValues(update);
            }

            function renderPhotosPanel() {
                const photos = (gallery.galleryPhotos ?? gallery.images) as
                    | File[]
                    | Image[];
                return photos.length ? (
                    <PhotosPanel removeHandler={removePhoto} photos={photos} />
                ) : null;
            }

            return (
                <ExpansionPanel key={index}>
                    <ExpansionPanelSummaryWrapper
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.checkedList.includes(id)}
                                    onChange={checkHandle}
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label={
                                gallery.galleryName
                                    ? `Galeria "${gallery.galleryName}"`
                                    : 'Galeria'
                            }
                        />
                    </ExpansionPanelSummaryWrapper>
                    <ExpansionPanelDetailsWrapper>
                        <WideField
                            name="galleryName"
                            value={gallery.galleryName}
                            onChange={nameHandle}
                            onBlur={props.handleBlur}
                            label="Nazwa"
                            variant="outlined"
                        />
                        <DragAndDropZone
                            acceptFiles={['image/jpeg', 'image/png']}
                            changeHandle={photoHandle}
                        />
                        {renderPhotosPanel()}
                    </ExpansionPanelDetailsWrapper>
                </ExpansionPanel>
            );
        });
    }

    return (
        <>
            <form onSubmit={props.handleSubmit}>{renderGallery()}</form>
            <BottomPanel<PhotoGalleryForm[]>
                actionHandleArg={props.values}
                primaryButtonActionHandle={props.addNewGallery}
                primaryButtonText="Dodaj"
                secondButtonText="Usuń"
                secondButtonActionHandle={props.removeGalleries}
            />
            <BottomNavBar
                prevStep={props.prevStep}
                showBack={true}
                nextStep={props.handleSubmit}
                buttonNextText="Dalej"
            />
        </>
    );
}
