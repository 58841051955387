import React from 'react';
import { Step } from '../../../../components/ProgressStepper/types';
import {
    Localization,
    Settings,
    BasicData,
    Documents,
    PhotoGallery,
} from '../../Templates/components';

export const editObjectSteps: Step[] = [
    {
        title: 'Informacje podstawowe',
        // @ts-ignore
        component: <BasicData />,
    },
    {
        title: 'Lokalizacja',
        // @ts-ignore
        component: <Localization />,
    },
    {
        title: 'Galeria zdjęć',
        // @ts-ignore
        component: <PhotoGallery />,
    },
    {
        title: 'Dokumentacja',
        // @ts-ignore
        component: <Documents />,
    },
    {
        title: 'Ustawienia obiektu',
        // @ts-ignore
        component: <Settings />,
    },
];
