import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import {
    BoxAppearance,
    BoxAppearanceVariable,
    SchematicField,
} from '../../../../../../Objects/types/AppearanceBase';
import { SelectBox, WideField } from '../../../../../../../../styled/';
import {
    StyledForm,
    ContentContainer,
    VerticalDivider,
} from '../../../../../../formStyled';
import { FormikAsyncSelect } from '../../../../../../../../tools/FormikFields';
import { BottomPanel } from '../../../../BottomPanel';
import { Schemas, Variable } from './components';
import { useFetchAsyncSelectData } from '../../../../../../Parameters/hooks/useFetchAsyncSelectData';
import { generateRandomString } from '../../../../../../../../tools';
import { SelectOption } from '../../../../../../../../types/SelectOption';
import { Parameter } from '../../../../../../Parameters/types/ParameterTypes';
import { DndGrid } from '../../../../../../../../components/DndGrid/DndGrid';
import { DragIndicator } from '@material-ui/icons';
import {
    arrayMove,
    SortEnd,
    SortEvent,
    SortableHandle,
} from 'react-sortable-hoc';
interface FieldsProps extends FormikProps<BoxAppearance> {
    selectedRows: string[];
    onCheckHandle: (rowId: string) => void;
    removeHandle: () => void;
    parameters: Parameter[];
}

export function Fields(props: FieldsProps) {
    const {
        fetchAppearanceBoxTypes,
        fetchParametersSchematics,
    } = useFetchAsyncSelectData();
    const [parameters, setParameters] = useState<BoxAppearanceVariable[]>(
        props.values.parameters,
    );
    function addNewVariable() {
        const parameters = [
            ...props.values.parameters,
            {
                tempId: generateRandomString(),
                label: '',
                value: '',
            },
        ];
        setParameters(parameters);
        props.setValues({
            ...props.values,
            parameters,
        });
    }

    function setParameter(rowId: string, value: SelectOption) {
        const parameters = props.values.parameters.map(parameter => {
            if (parameter.tempId === rowId) {
                return {
                    ...parameter,
                    ...value,
                };
            }
            return parameter;
        });
        setParameters(parameters);
        props.setValues({
            ...props.values,
            parameters,
        });
    }

    function onEndSortHandle(sort: SortEnd, event: SortEvent) {
        const params = arrayMove(parameters, sort.oldIndex, sort.newIndex);
        setParameters(params);
        props.setValues({
            ...props.values,
            parameters: params,
        });
    }
    const DragHandle = SortableHandle(() => (
        <DragIndicator style={{ cursor: 'grab' }} />
    ));

    function renderVariables() {
        return (
            <>
                <Typography>Lista zmiennych</Typography>
                <DndGrid
                    onEndSortHandle={onEndSortHandle}
                    headings={[]}
                    items={props.values.parameters.map((parameter, index) => ({
                        index,
                        key: parameter.tempId,
                        element: (
                            <>
                                <td>
                                    <Variable
                                        onCheck={props.onCheckHandle}
                                        isChecked={props.selectedRows.includes(
                                            parameter.tempId,
                                        )}
                                        key={parameter.tempId}
                                        parameter={parameter}
                                        setParameter={setParameter}
                                    />
                                </td>
                                <td style={{ width: '30px' }}>
                                    <DragHandle />
                                </td>
                            </>
                        ),
                    }))}
                />
                <BottomPanel<null>
                    actionHandleArg={null}
                    primaryButtonActionHandle={addNewVariable}
                    primaryButtonText="Dodaj"
                    secondButtonText="Usuń"
                    secondButtonActionHandle={props.removeHandle}
                />
            </>
        );
    }

    function setSchemasParameter(field: SchematicField, parameter: Parameter) {
        props.setValues({
            ...props.values,
            schematics: {
                id: props.values.schematics!.id,
                label: props.values.schematics!.label,
                fields: props.values.schematics!.fields.map(schematicField => {
                    if (schematicField.label === field.label) {
                        return {
                            ...schematicField,
                            parameter,
                        };
                    }

                    return schematicField;
                }),
            },
        });
    }

    function renderSchemas() {
        return (
            <>
                <Typography>Obsługa schematu obiektu</Typography>
                {props.values.schematics ? (
                    <Schemas
                        setParameter={setSchemasParameter}
                        parameters={props.parameters}
                        fields={props.values.schematics!.fields}
                    />
                ) : null}
            </>
        );
    }

    const isSchematicType =
        props.values.type && props.values.type.value === 'schematics';

    return (
        <StyledForm onSubmit={props.handleSubmit}>
            <ContentContainer>
                <WideField
                    name="name"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.name && props.touched.name}
                    label="Nazwa boksu"
                    variant="outlined"
                />
                <SelectBox>
                    <FormikAsyncSelect
                        defaultValue={props.values.type}
                        name="type"
                        cacheOptions
                        defaultOptions
                        loadOptions={fetchAppearanceBoxTypes}
                        error={props.errors.type && props.touched.type}
                        onChange={(option: any) =>
                            props.setFieldValue('type', option)
                        }
                        onBlur={props.handleBlur}
                        placeholder="Typ boksu"
                    />
                </SelectBox>
                {isSchematicType ? (
                    <SelectBox>
                        <FormikAsyncSelect
                            defaultValue={props.values.schematics}
                            name="schematics"
                            cacheOptions
                            defaultOptions
                            loadOptions={fetchParametersSchematics}
                            error={
                                props.errors.schematics &&
                                props.touched.schematics
                            }
                            onChange={(option: any) =>
                                props.setFieldValue('schematics', option)
                            }
                            onBlur={props.handleBlur}
                            placeholder="Schemat"
                        />
                    </SelectBox>
                ) : null}
                <WideField
                    name="description"
                    value={props.values.description}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    multiline
                    rows={5}
                    error={
                        props.errors.description && props.touched.description
                    }
                    label="Pełny opis"
                    variant="outlined"
                />
            </ContentContainer>
            <VerticalDivider />
            <ContentContainer>
                {isSchematicType ? renderSchemas() : renderVariables()}
            </ContentContainer>
        </StyledForm>
    );
}
