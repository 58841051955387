import React from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';

interface CellProps {
    children: React.ReactNode;
    width?: number | undefined;
    clickHandler?: (...args: any) => void;
    active?: boolean;
    direction?: 'asc' | 'desc';
}

export function HeaderCell(props: CellProps) {
    const clickHandler = () => (event: any) => {
        return !!props.clickHandler ? props.clickHandler() : null;
    };

    if (props.width) {
        return (
            <TableCell
                style={{
                    maxWidth: props.width,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                variant={'head'}
                onClick={clickHandler()}
                // sortDirection={'asc'}
            >
                {/* {props.children} */}
                <TableSortLabel
                    // active={orderBy === headCell.id}
                    // direction={orderBy === headCell.id ? order : 'asc'}
                    // onClick={createSortHandler(headCell.id)}
                    active={true}
                    direction={'asc'}
                >
                    {props.children}
                </TableSortLabel>
            </TableCell>
        );
    }

    return (
        <TableCell variant={'head'}>
            <TableSortLabel
                onClick={clickHandler()}
                active={props.active}
                direction={props.direction}
            >
                {props.children}
            </TableSortLabel>
        </TableCell>
    );
}
