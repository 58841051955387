/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, FormikProps } from 'formik';
import React, { useContext, useEffect } from 'react';

import { NotificationHandlersContext } from '../../../../../../../containers/App/components/AppWithMenu/AppWithMenu';
import { useAddEditSchematic } from '../../../../hooks/useAddEditSchematics';
import { SchematicForm } from '../../../../types/SchematicForm';
import { SchematicScheme } from './constants/validation';
import { Fields } from './Fields';

interface FormProps {
    organizationId: number;
    closeDrawer: () => void;
    isEdit: boolean;
    schematicForm: SchematicForm;
}

export function Form(props: FormProps) {
    const {
        schematicIsAdding,
        getSchematicsHandle,
        newSchematicHasAdded,
        schematicHasEditedState,
        schematicEditionIsPending,
        addNewSchematic,
        removeSchematic,
        editSchematicHandle,
        resetStoreHandle,
        hydrateForm,
        stateAddNewSchematic,
    } = useAddEditSchematic(props.organizationId);
    const notifyHandlers = useContext(NotificationHandlersContext);

    function onSubmit(schematic: SchematicForm) {
        console.log('schematic: ', schematic);
        if (props.isEdit) {
            editSchematicHandle(schematic);
            return;
        }
        addNewSchematic(schematic);
    }

    useEffect(() => {
        if (newSchematicHasAdded) {
            props.closeDrawer();
            notifyHandlers.showSuccess('Schemat został dodany');
            resetStoreHandle();
        }
        if (schematicHasEditedState) {
            props.closeDrawer();
            notifyHandlers.showSuccess('Schemat został zedytowany');
            resetStoreHandle();
        }
    }, [newSchematicHasAdded, schematicHasEditedState]);

    return (
        <Formik<SchematicForm>
            validationSchema={SchematicScheme}
            initialValues={props.schematicForm}
            onSubmit={onSubmit}
        >
            {(otherProps: FormikProps<SchematicForm>) => (
                <Fields
                    {...otherProps}
                    organizationId={props.organizationId}
                    isEdit={props.isEdit}
                />
            )}
        </Formik>
    );
}
