import { Heading } from '../../../../../../../../../../components/DataGrid/types';

export const headings: Heading[] = [
    {
        key: 'module',
        heading: '',
        type: 'string',
    },
    {
        key: 'unitPrice',
        heading: 'CENA JEDNOSTKOWA',
        type: 'string',
    },
    {
        key: 'clientPrice',
        heading: 'CENA KLIENTA',
        type: 'string',
    },
    {
        key: 'allowance',
        heading: 'RABAT %',
        type: 'num',
    },
    {
        key: 'licenseCount',
        heading: 'LICZBA LICENCJI',
        type: 'num',
    },
    {
        key: 'monthSummary',
        heading: 'SUMA MIESIĘCZNIE',
        type: 'string',
    },
];
