import React from 'react';
import Select, { InputActionMeta, OptionsType } from 'react-select';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ErrorContainer } from '../views/Login/components/Form/styled';

export function FormikTextField(props: any) {
    const { className, ...restProps } = props;
    return (
        <TextFieldContainer className={className}>
            <StyledTextField {...restProps} />
            {props.hasError ? (
                <ErrorContainer>{props.error}</ErrorContainer>
            ) : null}
        </TextFieldContainer>
    );
}

const TextFieldContainer = styled.div`
    margin-bottom: 20px;
`;

const StyledTextField = styled(TextField)`
    width: 100%;
`;

const selectStyles = (error = null) => ({
    multiValue: (base: any, state: any) => {
        return state.data.isFixed
            ? { ...base, backgroundColor: 'gray', padding: '5px' }
            : { ...base, padding: '5px' };
    },
    multiValueLabel: (base: any, state: any) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    menu: (provided: any) => ({
        ...provided,
        zIndex: 2,
    }),
    control: (provided: any) => ({
        ...provided,
        minHeight: 56,
        border: error ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.23)',
    }),
    placeholder: (provide: any) => ({
        ...provide,
        color: error ? 'red' : 'hsl(0,0%,50%)',
    }),
});

export function FormikSelect(props: any) {
    const { error, ...otherProps } = props;
    return (
        <SelectContainer>
            <Select
                isSearchable={true}
                noOptionsMessage={() => 'Brak'}
                styles={selectStyles(error)}
                {...otherProps}
            />
        </SelectContainer>
    );
}

export default class AsyncSelectWithInput extends AsyncSelect<any> {
    defaultOptions: OptionsType<any> | undefined = undefined;
    shouldComponentUpdate(prevProps: any, prevState: any) {
        if (prevState.defaultOptions?.length && !this.defaultOptions) {
            this.defaultOptions = prevState.defaultOptions;
        }
        return true;
    }
    handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'set-value') {
            this.setState({
                ...this.state,
                defaultOptions: this.defaultOptions,
            });
        }
        if (actionMeta.action === 'input-change') {
            this.setState({
                ...this.state,
                defaultOptions: this.defaultOptions?.filter(
                    option =>
                        (option.name ?? option.label)
                            ?.toLocaleLowerCase()
                            .indexOf(newValue.toLocaleLowerCase()) >= 0,
                ),
            });
        }
        return newValue;
    };
}

export function FormikAsyncSelect(props: any) {
    const { error, ...otherProps } = props;
    return (
        <SelectContainer>
            <AsyncSelectWithInput
                isSearchable={true}
                noOptionsMessage={() => 'Brak'}
                styles={selectStyles(error)}
                {...otherProps}
            />
        </SelectContainer>
    );
}

export function FormikCreteableSelect(props: any) {
    const { error, ...otherProps } = props;
    return (
        <SelectContainer>
            <CreatableSelect
                isSearchable={true}
                formatCreateLabel={userInput => `Stwórz ${userInput}`}
                noOptionsMessage={() => 'Brak'}
                styles={selectStyles(error)}
                onBlur={(event: any) => {
                    if (!event.target.value) {
                        return;
                    }
                    props.onChange({
                        label: event.target.value,
                        value: event.target.value,
                        __isNew__: true,
                    });
                }}
                {...otherProps}
            />
        </SelectContainer>
    );
}

export function FormikAsyncCreteableSelect(props: any) {
    const { error, ...otherProps } = props;
    return (
        <SelectContainer>
            <AsyncCreatableSelect
                isSearchable={true}
                noOptionsMessage={() => 'Brak'}
                formatCreateLabel={userInput => `Stwórz ${userInput}`}
                styles={selectStyles(error)}
                onBlur={(event: any) => {
                    if (!event.target.value) {
                        return;
                    }
                    props.onChange({
                        label: event.target.value,
                        value: event.target.value,
                        __isNew__: true,
                    });
                }}
                {...otherProps}
            />
        </SelectContainer>
    );
}

export function FormikCheckBox(props: any) {
    const { error, ...otherProps } = props;
    return (
        <FormControlLabel
            control={<Checkbox color="primary" {...otherProps} />}
            label={props.label}
        />
    );
}

const SelectContainer = styled.div`
    width: 100%;
`;
// margin-bottom: 20px;
