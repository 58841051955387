import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { Row, UsedParametersList } from './component';
import {
    Parameter,
    ParameterTypes,
} from '../../../../../Parameters/types/ParameterTypes';
import { generateRandomString } from '../../../../../../../tools';
import { BottomPanel } from '../../../BottomPanel';
import { ParameterDrawer } from '../../../../../Parameters/components/ParameterDrawer';
import { useDrawerVisible } from '../../../../../../../hooks';
import { useParameterForm } from '../../../../../Parameters/hooks/useParameterForm';
import { BottomNavBar } from '../../../../../../../components/ProgressStepper/components';
import { getTempIdOrId } from '../../../tools/getTempIdOrId';
import { validateRow } from '../../../../../../../tools/validateFields';
import { BIT_NUMBER_DATA } from '../../../../../Parameters/components/TopPanel/forms/Binary/constants/bitNumberSelectData';
import { isOnlyForReadingDefaultValue } from '../../../../../Parameters/constants/isOnlyForReadingDefaultValue';
import { ApproveAction } from '../../../../../../../components/ApproveAction';
import { ModalBody } from '../../../../../../../components/ModalBody';
import { Modal } from '@material-ui/core';
import { MachineObject } from '../../../../../Objects/types';
import { useVariables } from '../../../../hooks/useVariables';
import { searchUsedParameters } from '../../../tools/searchUsedParameters';
import { getInitDataByType } from '../../../../../Parameters/tools/getInitDataByType';

interface FieldsProps extends FormikProps<Parameter[]> {
    prevStepHandle: (value: Parameter[]) => void;
    nextStepHandle: (value: Parameter[]) => void;
    setRemovedParameters: (parameters: string[]) => void;
    formState: MachineObject;
}

export interface UsedParameters {
    isVisible: boolean;
    parameters: {
        name: string;
        id: string;
        steps: {
            [key: string]: string[];
        };
    }[];
}

export function Fields(props: FieldsProps) {
    const [values, setValues] = useState<Parameter[]>(props.values);
    const [selectedRows, selectRow] = useState<any>([]);
    const [removedApproveModal, setRemoveApproveModal] = useState<
        UsedParameters
    >({ isVisible: false, parameters: [] });
    const [rowErrors, setErrors] = useState<string[]>([]);
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const [, updateFormData] = useParameterForm();
    const { variables } = useVariables();

    const selectRowHandle = React.useCallback((parameter: Parameter) => {
        selectRow((selectedRows: any) => {
            if (selectedRows.includes(getTempIdOrId(parameter))) {
                selectRow(
                    selectedRows.filter(
                        (filteredRow: string | number) =>
                            filteredRow !== getTempIdOrId(parameter),
                    ),
                );
                return;
            }
            return [...selectedRows, getTempIdOrId(parameter)];
        });
    }, []);

    const setAddress = React.useCallback((parameter: Parameter, event: any) => {
        event.persist();
        setValues(currentValues => {
            return currentValues.map(filteredValue => {
                if (getTempIdOrId(filteredValue) === getTempIdOrId(parameter)) {
                    return {
                        ...filteredValue,
                        address: event.target.value,
                    };
                }
                return filteredValue;
            });
        });
    }, []);

    const setSelectName = React.useCallback(
        (parameter: Parameter, option: any) => {
            setValues(currentValues => {
                return currentValues.map(filteredValue => {
                    if (
                        getTempIdOrId(filteredValue) ===
                            getTempIdOrId(parameter) &&
                        option.hasOwnProperty('__isNew__')
                    ) {
                        return {
                            ...filteredValue,
                            name: option,
                            address: option.hasOwnProperty('__isNew__')
                                ? ''
                                : option.defaultRegister,
                        };
                    }

                    if (
                        getTempIdOrId(filteredValue) ===
                        getTempIdOrId(parameter)
                    ) {
                        return {
                            ...option.meta,
                        };
                    }

                    return filteredValue;
                });
            });
        },
        [],
    );

    const setSelectType = React.useCallback(
        (parameter: Parameter, value: any) => {
            setValues(currentValues => {
                return currentValues.map(filteredValue => {
                    if (
                        getTempIdOrId(filteredValue) ===
                        getTempIdOrId(parameter)
                    ) {
                        return {
                            ...getInitDataByType(value),
                            type: value,
                            name: filteredValue.name,
                            id: filteredValue.id,
                            tempId: filteredValue.tempId,
                            fullDescription: filteredValue.fullDescription,
                            shortDescription: filteredValue.shortDescription,
                            address: filteredValue.address,
                        };
                    }
                    return filteredValue;
                });
            });
        },
        [],
    );

    function prevHandle() {
        props.prevStepHandle(values);
    }

    function addNewRow() {
        setValues([
            ...values,
            {
                type: ParameterTypes.Binary,
                tempId: generateRandomString(),
                name: undefined,
                bitNumber: BIT_NUMBER_DATA[0],
                address: '',
                shortDescription: '',
                fullDescription: '',
                isDefaultShowingAtCharts: false,
                isAvailableForChart: true,
                isOnlyForReading: isOnlyForReadingDefaultValue,
            },
        ]);
    }

    function removeRow() {
        const rows = values
            .filter(filteredValues =>
                selectedRows.includes(getTempIdOrId(filteredValues)),
            )
            .map(item => ({
                name: item.name?.label || '',
                tempId: item.tempId,
            }));
        const parameters = rows.reduce((previousValue, currentValue) => {
            const steps = searchUsedParameters(
                props.formState,
                currentValue.tempId as string,
            );
            // @ts-ignore
            if (steps[currentValue.tempId]?.length) {
                const data = {
                    name: currentValue.name,
                    id: currentValue.tempId,
                    steps,
                };
                // @ts-ignore
                previousValue.push(data);
            }
            return previousValue;
        }, []);
        if (parameters.length) {
            setRemoveApproveModal({
                isVisible: true,
                parameters,
            });
            return;
        }
        removeParametersHandle();
    }

    function removeParametersHandle() {
        setValues(
            values.filter(
                filteredValues =>
                    !selectedRows.includes(getTempIdOrId(filteredValues)),
            ),
        );
        selectRow([]);
        closeApproveModal();
    }

    function closeApproveModal() {
        setRemoveApproveModal({ ...removedApproveModal, isVisible: false });
    }

    function editVariable(variable: Parameter) {
        updateFormData(variable);
        props.handleSubmit();
        openDrawer();
    }

    function updateVariableAction(parameter: Parameter) {
        setValues(
            values.map(filteredValues => {
                if (
                    getTempIdOrId(parameter) === getTempIdOrId(filteredValues)
                ) {
                    return parameter;
                }
                return filteredValues;
            }),
        );
        closeDrawer();
    }

    function handleSubmitAndNext() {
        //TODO to resolve
        // setTimeout(() => setErrors([]), 2000);

        const basicFields = ['address', 'shortDescription'];
        const figures = validateRow(
            values.filter(value => value.type === ParameterTypes.Figure),
            [
                ...basicFields,
                'format',
                'countAfterAt',
                'unitShowing',
                'unitRAW',
                'isOnlyForReading',
            ],
        );
        const orders = validateRow(
            values.filter(value => value.type === ParameterTypes.Order),
            [...basicFields, 'orderId'],
        );
        const calculated = validateRow(
            values.filter(value => value.type === ParameterTypes.Calculated),
            [...basicFields, 'aggregationMethod', 'unitShowing'],
        );
        const binary = validateRow(
            values.filter(value => value.type === ParameterTypes.Binary),
            [
                ...basicFields,
                'bitNumber',
                'presentationMode',
                'isOnlyForReading',
            ],
        );
        const errors = [...figures, ...orders, ...calculated, ...binary];
        if (errors.length) {
            setErrors(errors);
            return;
        }
        // props.handleSubmit();
        props.nextStepHandle(values);
    }

    function renderRows() {
        return values.map((parameter: Parameter, index) => (
            <Row
                variables={variables.filter(
                    variable =>
                        !values
                            .filter(value => value.name)
                            .map(variable => variable.name!.value)
                            .includes(variable!.label),
                )}
                editVariable={editVariable}
                formValues={values}
                setFormValue={setValues}
                key={index}
                hasError={rowErrors.includes(parameter.tempId as string)}
                parameter={parameter}
                isChecked={selectedRows.includes(getTempIdOrId(parameter))}
                onRowCheck={selectRowHandle}
                setAddress={setAddress}
                setSelectName={setSelectName}
                setSelectType={setSelectType}
            />
        ));
    }

    return (
        <>
            <Modal
                open={removedApproveModal.isVisible}
                onClose={closeApproveModal}
            >
                <ModalBody closeModalOnCLick={closeApproveModal}>
                    <ApproveAction
                        heading="Uwaga"
                        desc="Następujące parametry są użyte w innych krokach:"
                        approveOnClick={removeParametersHandle}
                        cancelOnClick={closeApproveModal}
                    >
                        <UsedParametersList
                            usedParameters={removedApproveModal}
                        />
                    </ApproveAction>
                </ModalBody>
            </Modal>
            <ParameterDrawer
                onSubmitHandle={updateVariableAction}
                openAddParameter={openDrawer}
                isOpen={drawerState}
                closeAddParameter={closeDrawer}
            />
            {renderRows()}
            <BottomPanel<null>
                primaryButtonActionHandle={addNewRow}
                actionHandleArg={null}
                primaryButtonText="Dodaj"
                secondButtonActionHandle={removeRow}
                secondButtonText="Usuń"
            />
            <BottomNavBar
                prevStep={prevHandle}
                nextStep={handleSubmitAndNext}
                showBack={true}
                buttonNextText="Dalej"
            />
        </>
    );
}
