import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DataGrid } from '../../../components/DataGrid';
import { Heading } from '../../../components/Heading';
import { TopPanel } from '../../../components';
import { useDrawerVisible } from '../../../hooks';
import { request } from '../../../tools';
import { ContentContainer } from '../styled';
import { headings } from './constants/headings';
import { useSchematics } from './hooks/useSchematics';
import { useAddEditSchematic } from './hooks/useAddEditSchematics';
import { Schematic } from './types/Schematics';
import { AddEditSchematic } from './components/AddEditGroup';
import { Drawer } from '../../../components/Drawer';

interface SchematicsProps extends RouteComponentProps<{ id: string }> {}

export function Schematics(props: SchematicsProps) {
    const { id } = props.match.params;
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const [isEdit, setEditState] = useState(false);
    const { hydrateForm, removeSchematic } = useAddEditSchematic(Number(id));
    const { schematics } = useSchematics(Number(id));

    function closeDrawerHandle() {
        setEditState(false);
        closeDrawer();
    }

    function removeSchematicModal() {}

    function downloadFile(schematic: Schematic) {
        try {
            const url = `${process.env.REACT_APP_API_URL}/parameter/schemas/${schematic.id}/file`;
            const element = document.createElement('a');
            element.href = url;
            document.body.appendChild(element);
            element.click();
            element.remove();
        } catch (e) {
            console.log(e);
        }
    }

    function edit(schematic: Schematic) {
        setEditState(true);
        hydrateForm({
            file: schematic.file_name,
            name: schematic.label,
            id: schematic.id,
        });
        openDrawer();
    }

    function onClick() {}

    const preparedSchematics = schematics.map(schematic => {
        const menuComponents = [
            {
                name: 'Edytuj',
                clickHandle: edit,
                args: schematic,
            },
            {
                name: 'Pobierz',
                clickHandle: downloadFile,
                args: schematic,
            },
        ];

        return {
            ...schematic,
            menuComponents,
        };
    });

    return (
        <ContentContainer>
            <Heading color="black">Schematy</Heading>
            <TopPanel
                drawerSteps={[
                    {
                        title: isEdit ? 'Edycja' : 'Dodawanie',
                        // @ts-ignore
                        component: <AddEditSchematic isEdit={isEdit} />,
                    },
                ]}
                label="Dodaj nowy"
                hasProgressStepper={true}
                isOpen={drawerState}
                openAddTemplate={openDrawer}
                closeAddTemplate={closeDrawerHandle}
            />
            <DataGrid
                rowClickHandle={onClick}
                showMenu={true}
                headings={headings}
                data={preparedSchematics}
            />
        </ContentContainer>
    );
}
