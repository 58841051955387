import React, { useEffect, useState } from 'react';
import { gridKeys, headings } from './constants';
import { useTemplates } from './hooks/useTemplates';
import { ContentContainer } from '../styled';
import { useDrawerVisible } from '../../../hooks';
import { useObjectForm } from '../Objects/hooks';
import { Modal } from '@material-ui/core';
import {
    ModalBody,
    ApproveAction,
    Heading,
    DataGrid,
    InformationModalBody,
} from '../../../components';
import { addTemplateSteps } from './constants/addTemplateSteps';
import { TopPanel } from '../Objects/components';
import { useDrafts } from '../Objects/hooks/useDrafts';

export function Templates() {
    const { drawerState, openDrawer, closeDrawer } = useDrawerVisible();
    const [, setData] = useObjectForm();
    const [draftsIsOpened, setDraftsVisibleState] = useState(false);
    const { drafts, openDraft, getAllDrafts, removeDraft } = useDrafts();
    const [removedTemplate, setRemovedTemplate] = useState({
        template: null,
        modalIsOpen: false,
    });
    const [infoTemplate, setInfoTemplate] = useState({
        template: { objectNames: [] },
        modalIsOpen: false,
    });
    const {
        removeTemplate,
        templates,
        fetchTemplates,
        hasFetched,
        isFetching,
    } = useTemplates();

    useEffect(() => {
        if (!hasFetched && !isFetching) {
            fetchTemplates();
        }
    }, [fetchTemplates, hasFetched, isFetching]);

    function duplicateTemplate({ template }: any) {
        setData(JSON.parse(template.metadata));
        openDrawer();
    }

    function handleCloseModal() {
        setRemovedTemplate({
            template: null,
            modalIsOpen: false,
        });
    }

    function removeTemplateShowApproveModal({ template }: any) {
        setRemovedTemplate({
            template,
            modalIsOpen: true,
        });
    }

    function infoTemplateShowModal({ template }: any) {
        setInfoTemplate({
            template,
            modalIsOpen: true,
        });
    }

    function closeInfoTemplateShowModal() {
        setInfoTemplate({
            template: { objectNames: [] },
            modalIsOpen: false,
        });
    }

    async function removeTemplateHandle() {
        removeTemplate((removedTemplate.template as any).id);
        setRemovedTemplate({
            template: null,
            modalIsOpen: false,
        });
    }

    const preparedTemplates = templates.map(template => {
        const menuComponents = [
            {
                name: 'Duplikuj',
                clickHandle: duplicateTemplate,
                args: {
                    template,
                },
            },
        ];

        if (!template.objectNames.length) {
            menuComponents.push({
                name: 'Usuń',
                clickHandle: removeTemplateShowApproveModal,
                args: {
                    template,
                },
            });
        }

        if (template.objectNames.length) {
            menuComponents.push({
                name: 'Info',
                clickHandle: infoTemplateShowModal,
                args: {
                    template,
                },
            });
        }

        return {
            ...template,
            objectsCount: template.objectNames.length,
            menuComponents,
        };
    });

    function onClick(item: any) {}

    async function openDrafts() {
        await getAllDrafts();
        setDraftsVisibleState(true);
    }

    function closeDrafts() {
        setDraftsVisibleState(false);
    }

    function openDraftHandle(id: string) {
        openDraft(id);
        closeDrafts();
        openDrawer();
    }

    return (
        <ContentContainer>
            <Heading color="black">Szablony</Heading>
            <Modal
                open={removedTemplate.modalIsOpen}
                onClose={handleCloseModal}
            >
                <ModalBody closeModalOnCLick={handleCloseModal}>
                    <ApproveAction
                        heading="Usuwanie szablonu"
                        desc="Czy na pewno chcesz usunąc szablon?"
                        approveOnClick={removeTemplateHandle}
                        cancelOnClick={handleCloseModal}
                    />
                </ModalBody>
            </Modal>
            <Modal
                open={infoTemplate.modalIsOpen}
                onClose={closeInfoTemplateShowModal}
            >
                <ModalBody closeModalOnCLick={closeInfoTemplateShowModal}>
                    <InformationModalBody
                        heading="Szablon jest użyty w następujących obiektach:"
                        size="h6"
                        desc={(infoTemplate.template as any).objectNames.map(
                            (item: any, index: number) =>
                                `${index + 1}. ${item} \n`,
                        )}
                        okClick={closeInfoTemplateShowModal}
                    />
                </ModalBody>
            </Modal>
            <TopPanel
                removeDraft={removeDraft}
                drawerSteps={addTemplateSteps}
                openDraft={openDraftHandle}
                drafts={drafts}
                openDrafts={openDrafts}
                draftsIsOpened={draftsIsOpened}
                closeDrafts={closeDrafts}
                isOpen={drawerState}
                openAddObject={openDrawer}
                closeAddObject={closeDrawer}
            />
            <DataGrid
                rowClickHandle={onClick}
                showMenu={true}
                keys={gridKeys}
                headings={headings}
                data={preparedTemplates}
            />
        </ContentContainer>
    );
}
