import React from 'react';
import {
    Paper,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { BodyContainer, BodyItem } from './components';
import { SortEnd, SortEvent } from 'react-sortable-hoc';

export interface DndGridItem {
    key: string;
    element: JSX.Element;
    index: number;
}

interface DndGridProps {
    headings: string[];
    items: DndGridItem[];
    onEndSortHandle: (sort: SortEnd, event: SortEvent) => void;
}
export function DndGrid(props: DndGridProps) {
    const handleSortStart = ({ node }: any) => {
        const tds = document.getElementsByClassName('sortable-active')[0]
            .childNodes;
        node.childNodes.forEach(
            (node: any, idx: any) =>
                ((tds[idx] as any).style.width = `${node.offsetWidth}px`),
        );
    };

    return (
        <Paper>
            <Table size={'small'}>
                {!!props.headings.length && (
                    <TableHead>
                        <TableRow>
                            {props.headings.map((heading, index) => (
                                <TableCell key={index}>{heading}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}

                <BodyContainer
                    helperClass="sortable-active"
                    onSortStart={handleSortStart}
                    onSortEnd={props.onEndSortHandle}
                >
                    {props.items.map(item => (
                        <BodyItem
                            key={item.index}
                            index={item.index}
                            item={item.element}
                        />
                    ))}
                </BodyContainer>
            </Table>
        </Paper>
    );
}
