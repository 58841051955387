import { getTempIdOrId } from '../views/Organization/Templates/components/tools/getTempIdOrId';

export function validateFields(
    formData: any,
    fields: string[],
    errorFields: string[] = [],
) {
    fields.forEach((field: string) => {
        if (!isValid(formData, field)) {
            errorFields.push(field);
        }
    });

    return errorFields;
}

export function validateRow(
    rows: any[],
    fields: string[],
    errorRows: string[] = [],
) {
    rows.forEach((row: any) => {
        fields.forEach((field: any) => {
            if (
                !isValid(row, field) &&
                !errorRows.includes(getTempIdOrId(row))
            ) {
                errorRows.push(getTempIdOrId(row));
            }
        });
    });
    return errorRows;
}

function isValid(option: any, key: string): boolean {
    if (
        option[key] &&
        typeof option[key] === 'object' &&
        !Object.values(option[key]).join('')
    ) {
        return false;
    }

    return !(typeof option[key] !== 'object' &&
        (option[key] === undefined || option[key] === null || option[key].length===0));
}