import { Organization } from '../../../types';
import { ActionTypes, OrganizationUnion } from '../models/actionTypes';
import { FormState } from '../Organizations/types';
import { addFormInitState } from './constants/addFormInitState';

export interface OrganizationsState {
    organizationsIsFetching: boolean;
    addOrganizationForm: FormState;
    organizationsFetched: boolean;
    organizations: Organization[];
    errorMessage: string;
    addOrganizationPending: boolean;
    organizationIsAdded: boolean;
    addOrganizationErrorMessage: string;
}

const initialState: OrganizationsState = {
    organizations: [],
    addOrganizationForm: addFormInitState,
    organizationsFetched: false,
    organizationsIsFetching: false,
    errorMessage: '',
    addOrganizationPending: false,
    addOrganizationErrorMessage: '',
    organizationIsAdded: false,
};

export function organizationReducer(
    state: OrganizationsState = initialState,
    action: OrganizationUnion,
): OrganizationsState {
    switch (action.type) {
        case ActionTypes.GetOrganizations: {
            return {
                ...state,
                organizationsIsFetching: true,
            };
        }
        case ActionTypes.RemoveOrganizationSuccess: {
            const findIndex = state.organizations.findIndex(
                org => org.id === action.id,
            );
            if (findIndex >= 0) {
                state.organizations.splice(findIndex, 1);
            }
            return {
                ...state,
                organizations: [...state.organizations],
            };
        }

        case ActionTypes.SetFormDataValue: {
            return {
                ...state,
                addOrganizationForm: {
                    ...state.addOrganizationForm,
                    ...action.formData,
                },
            };
        }

        case ActionTypes.GetOrganizationsFailed: {
            return {
                ...state,
                organizationsIsFetching: false,
                organizationsFetched: false,
                organizations: [],
                errorMessage: action.errorMessage,
            };
        }

        case ActionTypes.GetOrganizationsSuccess: {
            return {
                ...state,
                organizations: action.organizations,
                organizationsFetched: true,
                organizationsIsFetching: false,
            };
        }

        case ActionTypes.AddOrganization: {
            return {
                ...state,
                addOrganizationPending: true,
            };
        }

        case ActionTypes.ResetError: {
            return {
                ...state,
                errorMessage: '',
                addOrganizationErrorMessage: '',
            };
        }

        case ActionTypes.ResetStore: {
            return {
                ...initialState,
                organizations: state.organizations,
                organizationsFetched: state.organizationsFetched,
                organizationsIsFetching: state.organizationsIsFetching,
            };
        }

        case ActionTypes.AddOrganizationSuccess: {
            return {
                ...state,
                organizationIsAdded: true,
                addOrganizationPending: false,
            };
        }

        case ActionTypes.AddOrganizationFailed: {
            return {
                ...state,
                organizationIsAdded: false,
                addOrganizationPending: false,
                addOrganizationErrorMessage: action.errorMessage,
            };
        }

        default: {
            return state;
        }
    }
}
