import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import { useEffect } from 'react';
import {
    fetchedSchematics,
    schematicsIsFetching,
    schematicsHasFetched,
} from '../selectors/schematics';
import { changeOrder, getSchematics } from '../actions/schematics';
import { Schematic } from '../types/Schematics';

interface UseSchematicsOutput {
    schematics: Schematic[];
    schematicsIsFetchingState: boolean;
    schematicsHasFetchedState: boolean;
    updateSchematics: (schematics: Schematic[]) => void;
}

export function useSchematics(organizationId: number): UseSchematicsOutput {
    const dispatch = useDispatch();
    const schematics = useSelector((state: AppState) =>
        fetchedSchematics(state),
    );
    const schematicsIsFetchingState = useSelector((state: AppState) =>
        schematicsIsFetching(state),
    );
    const schematicsHasFetchedState = useSelector((state: AppState) =>
        schematicsHasFetched(state),
    );

    function updateSchematics(schematics: Schematic[]) {
        dispatch(changeOrder(schematics, organizationId));
    }

    useEffect(() => {
        if (!schematicsHasFetchedState && !schematicsIsFetchingState) {
            dispatch(getSchematics(organizationId));
        }
    }, [
        dispatch,
        schematicsHasFetchedState,
        schematicsIsFetchingState,
        organizationId,
    ]);

    return {
        schematics,
        updateSchematics,
        schematicsIsFetchingState,
        schematicsHasFetchedState,
    };
}
