import { Dispatch } from 'redux';
import { request } from '../../../../tools';
import { Schematics, SchematicsById } from '../../../../api/api';
import { Schematic } from '../types/Schematics';
import { SchematicsActionTypes } from '../models/actionTypes';
import { SchematicForm } from '../types/SchematicForm';

export function getSchematics(organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SchematicsActionTypes.FetchSchematics,
        });

        try {
            const schematics = await request('GET', Schematics());
            dispatch({
                type: SchematicsActionTypes.FetchSchematicsSuccess,
                schematics,
            });
        } catch (e) {}
    };
}

export function editSchematic(
    schematic: SchematicForm,
    organizationId: number,
) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SchematicsActionTypes.EditSchematic,
        });
        const data = new FormData();
        if (schematic.name) {
            data.append('name', schematic.name);
        }
        if (schematic.file instanceof File) {
            data.append('file', schematic.file);
        }
        if (schematic.id) {
            try {
                const result = await request(
                    'POST',
                    SchematicsById(schematic.id),
                    data,
                    '?_method=PATCH',
                );
                dispatch({
                    type: SchematicsActionTypes.EditSchematicSuccess,
                    schematic: result,
                });
            } catch (e) {}
        }
    };
}

export function addSchematic(schematic: SchematicForm, organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SchematicsActionTypes.AddNewSchematic,
        });
        const data = new FormData();
        if (schematic.name) {
            data.append('name', schematic.name);
        }
        if (schematic.file instanceof File) {
            data.append('file', schematic.file);
        }

        try {
            const schematic = await request('POST', Schematics(), data);
            dispatch({
                type: SchematicsActionTypes.AddNewSchematicSuccess,
                schematic,
            });
        } catch (e) {}
    };
}

export function changeOrder(schematics: Schematic[], organizationId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SchematicsActionTypes.UpdateOrder,
            schematics,
        });

        const preparedSchematics = schematics.map((schematic, index) => ({
            order: index,
            value: schematic.id,
        }));

        try {
            await request(
                'PATCH',
                Schematics(),
                JSON.stringify(preparedSchematics),
            );
        } catch (e) {}
    };
}

export function resetStore() {
    return {
        type: SchematicsActionTypes.ResetStore,
    };
}

export function hydrateEditForm(schematic: SchematicForm) {
    return {
        type: SchematicsActionTypes.UpdateEditForm,
        schematic,
    };
}

export async function removeSchematicAction(
    organizationId: number,
    schematic: Schematic,
) {
    try {
        await request('DELETE', SchematicsById(schematic.id as number));
    } catch (e) {}
}
