import { Heading } from '../../../../../../../../components/DataGrid/types';

export const headingsKeys = ['galleryName', 'galleryPhotos'];
export const headings: Heading[] = [
    {
        key: 'galleryName',
        heading: 'Nazwa',
        type: 'string',
    },
    {
        key: 'galleryPhotos',
        heading: 'Ilość',
        type: 'num',
    },
];
