import { Heading } from '../../../../components/DataGrid/types';

export const headings: Heading[] = [
    {
        key: ['label'],
        heading: 'Nazwa',
        type: 'string',
    },
    {
        key: 'created_at',
        heading: 'Data stworzenia',
        type: 'date',
    },
    {
        key: 'file_name',
        heading: 'Nazwa pliku',
        type: 'string',
    },
];
