import { Dispatch } from 'redux';
import { ActionTypes } from '../models/actionTypes';
import { Organization as IOrganization } from '../../../types';
import { request } from '../../../tools';
import {
    AddOrganization,
    Organization,
    OrganizationRemove,
} from '../../../api/api';
import { FormState } from '../Organizations/types';

export function getOrganizations() {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.GetOrganizations,
        });

        try {
            const response: IOrganization[] = await request<IOrganization[]>(
                'GET',
                Organization,
            );
            dispatch(getOrganizationsSuccess(response));
        } catch (err) {
            dispatch(getOrganizationsFailed(err));
        }
    };
}
export function removeOrganization(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.RemoveOrganization,
        });

        try {
            // await request<any>('DELETE', OrganizationRemove(id));
            dispatch(removeOrganizationsSuccess(id));
        } catch (err) {
            dispatch(removeOrganizationsFailed(err));
        }
    };
}

function removeOrganizationsSuccess(id: number) {
    return {
        type: ActionTypes.RemoveOrganizationSuccess,
        id,
    };
}

function removeOrganizationsFailed(errorMessage: string) {
    return {
        type: ActionTypes.RemoveOrganizationSuccess,
        errorMessage,
    };
}

function getOrganizationsSuccess(organizations: IOrganization[]) {
    return {
        type: ActionTypes.GetOrganizationsSuccess,
        organizations,
    };
}

function getOrganizationsFailed(errorMessage: string) {
    return {
        type: ActionTypes.GetOrganizationsFailed,
        errorMessage,
    };
}

export function setFormDataValue(formData: Partial<FormState>) {
    return {
        type: ActionTypes.SetFormDataValue,
        formData,
    };
}

export function resetStore() {
    return {
        type: ActionTypes.ResetStore,
    };
}

export function resetError() {
    return {
        type: ActionTypes.ResetError,
    };
}

export function addNewOrganization(formData: FormState) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionTypes.AddOrganization,
        });
        const preparedFormData = {
            ...formData,
            billing: {
                ...formData.billing,
                dateFrom: new Date(
                    formData.billing.dateFrom
                        .split('.')
                        .reverse()
                        .join('/'),
                ),
                dateTo: new Date(
                    formData.billing.dateTo
                        .split('.')
                        .reverse()
                        .join('/'),
                ).toISOString(),
            },
            license: {
                ...formData.license,
                dateFrom: new Date(
                    formData.license.dateFrom
                        .split('.')
                        .reverse()
                        .join('/'),
                ).toISOString(),
                dateTo: new Date(
                    formData.license.dateTo
                        .split('.')
                        .reverse()
                        .join('/'),
                ).toISOString(),
            },
        };

        try {
            await request(
                'POST',
                AddOrganization,
                JSON.stringify(preparedFormData),
            );
            dispatch(addNewOrganizationSuccess());
        } catch (err) {
            const res = await err;
            dispatch(addNewOrganizationFailure(res));
        }
    };
}

function addNewOrganizationSuccess() {
    return {
        type: ActionTypes.AddOrganizationSuccess,
    };
}

function addNewOrganizationFailure(errorMessage: string) {
    return {
        type: ActionTypes.AddOrganizationFailed,
        errorMessage,
    };
}
