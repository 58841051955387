import React from 'react';
import { TableCell } from '@material-ui/core';
import { RowMenu } from '../../../../../components/DataGrid/components/RowMenu';
import { IMenuItem } from '../../../../../components/DataGrid/components/RowMenu/RowMenu';
import { MachineObject } from '../../types';

interface GridBodyItemProps {
    object: MachineObject;
    menuComponents: IMenuItem[];
}

export function GridBodyItem(props: GridBodyItemProps) {
    return (
        <>
            <TableCell>{props.object.basicData.name}</TableCell>
            <TableCell>{props.object.basicData.shortName}</TableCell>
            <TableCell>{props.object.localization.town}</TableCell>
            <TableCell>{props.object.basicData.ipAddress}</TableCell>
            <TableCell>{props.object.basicData.templateName}</TableCell>
            <RowMenu menuComponents={props.menuComponents} />
        </>
    );
}
