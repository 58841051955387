import { Heading } from '../../../../../../../../../../../components/DataGrid/types';

export const headings: Heading[] = [
    {
        key: 'monthSummary',
        heading: 'SUMA MIESIĘCZNIE',
        type: 'string',
    },
    {
        key: 'monthSummaryOffer',
        heading: 'SUMA MIESIĘCZNIE Z PROMOCJĄ',
        type: 'string',
    },
    {
        key: 'offer',
        heading: 'PROMOCJA %',
        type: 'string',
    },
];
