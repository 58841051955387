import { Heading } from '../../../../../../components/DataGrid/types';

export const headings: Heading[] = [
    {
        key: 'name',
        heading: 'Imię i nazwisko',
        type: 'string',
    },
    {
        key: 'email',
        heading: 'E-mail',
        type: 'string',
    },
    {
        key: 'addDate',
        heading: 'Data utworzenia',
        type: 'date',
    },
];
