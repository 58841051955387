import { Heading } from '../../../../../../../../components/DataGrid/types';

export const summaryPeriodDataGridKeys: string[] = ['periodSummary'];
export const summaryPeriodDataGridHeading: Heading[] = [
    {
        key: 'periodSummary',
        heading: 'SUMA ZA CAŁY OKRES TRWANIA OFERTY',
        type: 'string',
    },
];
