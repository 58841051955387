import React from 'react';
import { Form } from './components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAddEditSchematic } from '../../hooks/useAddEditSchematics';

interface AddEditSchematicProps extends RouteComponentProps<{ id: string }> {
    closeDrawer: () => void;
    isEdit?: boolean;
}

function Component(props: AddEditSchematicProps) {
    const { id } = props.match.params;
    const { stateAddNewSchematic } = useAddEditSchematic(Number(id));
    return (
        <Form
            isEdit={props.isEdit || false}
            organizationId={Number(id)}
            closeDrawer={props.closeDrawer}
            schematicForm={stateAddNewSchematic}
        />
    );
}

export const AddEditSchematic = withRouter(Component);
