import { SchematicsActionTypes, SchematicsUnion } from '../models/actionTypes';
import { SchematicForm } from '../types/SchematicForm';
import { Schematic } from '../types/Schematics';
const initAddSchematicValues = {
    file: null,
    name: null,
};
interface SchematicsState {
    schematics: Schematic[];
    schematicsHasFetched: boolean;
    schematicsIsFetching: boolean;
    schematicIsAdding: boolean;
    schematicHasAdded: boolean;
    schematicHasEdited: boolean;
    schematicEditionIsPending: boolean;
    addSchematicForm: SchematicForm;
}

const initState: SchematicsState = {
    schematics: [],
    schematicHasAdded: false,
    schematicIsAdding: false,
    schematicsHasFetched: false,
    schematicsIsFetching: false,
    schematicHasEdited: false,
    schematicEditionIsPending: false,
    addSchematicForm: initAddSchematicValues,
};

export function schematicsReducer(
    state: SchematicsState = initState,
    action: SchematicsUnion,
): SchematicsState {
    switch (action.type) {
        case SchematicsActionTypes.AddNewSchematic: {
            return {
                ...state,
                schematicIsAdding: true,
            };
        }

        case SchematicsActionTypes.AddNewSchematicSuccess: {
            return {
                ...state,
                schematics: [action.schematic, ...state.schematics],
                schematicIsAdding: false,
                schematicHasAdded: true,
            };
        }

        case SchematicsActionTypes.FetchSchematics: {
            return {
                ...state,
                schematicsIsFetching: true,
            };
        }

        case SchematicsActionTypes.FetchSchematicsSuccess: {
            return {
                ...state,
                schematicsIsFetching: false,
                schematicsHasFetched: true,
                schematics: action.schematics,
            };
        }

        case SchematicsActionTypes.UpdateOrder: {
            return {
                ...state,
                schematics: [...action.schematics],
            };
        }

        case SchematicsActionTypes.FetchSchematicsFailed: {
            return {
                ...state,
                schematics: [],
                schematicsHasFetched: false,
                schematicsIsFetching: false,
            };
        }

        case SchematicsActionTypes.UpdateEditForm: {
            return {
                ...state,
                addSchematicForm: action.schematic,
            };
        }

        case SchematicsActionTypes.EditSchematic: {
            return {
                ...state,
                schematicEditionIsPending: true,
            };
        }

        case SchematicsActionTypes.EditSchematicSuccess: {
            const index = state.schematics.findIndex(
                s => s.id === action.schematic.id,
            );
            if (index >= 0) {
                state.schematics.splice(index, 1, action.schematic);
            }
            return {
                ...state,
                schematics: state.schematics,
                schematicEditionIsPending: false,
                schematicHasEdited: true,
            };
        }

        case SchematicsActionTypes.ResetStore: {
            return {
                ...state,
                schematicIsAdding: false,
                schematicHasAdded: false,
                addSchematicForm: initAddSchematicValues,
                schematicHasEdited: false,
                schematicEditionIsPending: false,
            };
        }

        default:
            return state;
    }
}
