import { AppState } from '../../../../reducer';
import { SchematicForm } from '../types/SchematicForm';
import { Schematic } from '../types/Schematics';

function getSchematicsState(state: AppState) {
    return state.schematics;
}

export function fetchedSchematics(state: AppState): Schematic[] {
    return getSchematicsState(state).schematics;
}

export function schematicsIsFetching(state: AppState): boolean {
    return getSchematicsState(state).schematicsIsFetching;
}

export function schematicsHasFetched(state: AppState): boolean {
    return getSchematicsState(state).schematicsHasFetched;
}

export function schematicAddingPending(state: AppState): boolean {
    return getSchematicsState(state).schematicIsAdding;
}

export function schematicEditionPending(state: AppState): boolean {
    return getSchematicsState(state).schematicEditionIsPending;
}

export function schematicHasEdited(state: AppState): boolean {
    return getSchematicsState(state).schematicHasEdited;
}

export function schematicHasAdded(state: AppState): boolean {
    return getSchematicsState(state).schematicHasAdded;
}

export function addSchematicForm(state: AppState): SchematicForm {
    return getSchematicsState(state).addSchematicForm;
}
