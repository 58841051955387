import {
    Description,
    Equalizer,
    LocalAtm,
    LocalDrink,
    LockOpen,
    SupervisorAccount,
    ArtTrack,
    AccessAlarm,
    Book,
    DeveloperBoard,
} from '@material-ui/icons';
import React from 'react';
import { MenuItem } from '../../../../../components/DeepMenu/components/Menu/types';

export const menuItems: MenuItem[] = [
    {
        title: 'Użytkownicy',
        icon: <SupervisorAccount />,
        url: 'users',
    },
    {
        title: 'Płatności',
        icon: <LocalAtm />,
        url: 'billing',
    },
    {
        title: 'Logi',
        icon: <Description />,
        url: 'logs',
    },
    {
        title: 'Grupy',
        icon: <Book />,
        url: 'groups',
    },
    {
        title: 'Szablony',
        icon: <Book />,
        url: 'templates',
    },
    {
        title: 'Obiekty',
        icon: <LocalDrink />,
        url: 'objects',
    },
    {
        title: 'Alarmy',
        icon: <AccessAlarm />,
        url: 'alarms',
    },
    {
        title: 'Schematy',
        icon: <DeveloperBoard />,
        url: 'schematics',
    },
    {
        title: 'Parametry',
        icon: <ArtTrack />,
        url: 'parameters',
    },
    {
        title: 'Statystyki',
        icon: <Equalizer />,
        url: 'statistics',
    },
    {
        title: 'Zaloguj sie jako admin',
        icon: <LockOpen />,
        url: 'login-as-admin',
    },
];

export const menuItemsRzeszow: MenuItem[] = [
    {
        title: 'Użytkownicy',
        icon: <SupervisorAccount />,
        url: 'users',
    },
    {
        title: 'Grupy',
        icon: <Book />,
        url: 'groups',
    },
    {
        title: 'Szablony',
        icon: <Book />,
        url: 'templates',
    },
    {
        title: 'Obiekty',
        icon: <LocalDrink />,
        url: 'objects',
    },
];
