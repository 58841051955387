import React, { useEffect, useState } from 'react';

import { useOrganizationsState } from '../hooks/useOrganizationsState';
import { withManageMenu } from '../hocs/withManageMenu';
import { DataGrid } from '../../../components/DataGrid';
import { TopPanel } from './components/TopPanel';
import { Organization } from '../../../types';
import { ContentContainer } from './styled';
import { tableHeaders, tableKeys } from './constants';
import { Loader } from '../../../components/Loader';
import { LoaderContainer } from '../../../styled/LoaderContainer';
import { useGlobalAdmin } from '../../../hooks/useGlobalAdmin';
import { IMenuItem } from '../../../components/DataGrid/components/RowMenu/RowMenu';
import { Modal } from '@material-ui/core';
import { ModalBody, ApproveAction } from '../../../components';

function Component() {
    const [
        ,
        ,
        allOrganizations,
        fetchOrganizations,
        removeOrg,
    ] = useOrganizationsState();

    const { isGlobalAdmin, redirectToOrganization } = useGlobalAdmin();
    const [orgToRemove, setOrgToRemove] = useState<{
        org: Organization | null;
        modalIsOpen: boolean;
    }>({
        org: null,
        modalIsOpen: false,
    });

    useEffect(() => {
        if (!isGlobalAdmin) {
            redirectToOrganization();
        } else {
            fetchOrganizations();
        }
        // eslint-disable-next-line
    }, [isGlobalAdmin]);

    if (!allOrganizations.length) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }
    function remove() {
        orgToRemove.org?.id && removeOrg(orgToRemove.org?.id);
        handleCloseModal();
    }

    function removeModal(org: Organization) {
        setOrgToRemove({
            modalIsOpen: true,
            org,
        });
    }

    function handleCloseModal() {
        setOrgToRemove({
            modalIsOpen: false,
            org: null,
        });
    }

    const preparedOrganizations = allOrganizations.map(org => {
        const menuComponents: any[] = [
            {
                name: 'Otwórz szczególy',
                linkPath: `/organization/${org.id}/details`,
            },
        ];
        if (org.count === 0) {
            menuComponents.push({
                name: 'Usuń',
                args: org,
                clickHandle: removeModal,
            });
        }
        return {
            ...org,
            menuComponents,
        };
    });
    return (
        <ContentContainer>
            <Modal open={orgToRemove.modalIsOpen} onClose={handleCloseModal}>
                <ModalBody closeModalOnCLick={handleCloseModal}>
                    <ApproveAction
                        heading="Usuwanie projektu"
                        desc="Czy na pewno chcesz usunąc projekt?"
                        approveOnClick={remove}
                        cancelOnClick={handleCloseModal}
                    />
                </ModalBody>
            </Modal>
            <TopPanel />
            <DataGrid<Organization>
                showMenu={true}
                headings={tableHeaders}
                data={preparedOrganizations}
            />
        </ContentContainer>
    );
}

export const Organizations = withManageMenu(Component);
