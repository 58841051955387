import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducer';
import {
    organizations,
    organizationsFetched,
    organizationsFetching,
} from '../selectors/organizations';
import { Organization } from '../../../types';
import { getOrganizations, removeOrganization } from '../actions/organizations';

type UseOrganizationsStateOutput = [
    boolean,
    boolean,
    Organization[],
    () => void,
    (id: number) => void,
];

export function useOrganizationsState(): UseOrganizationsStateOutput {
    const dispatch = useDispatch();

    const organizationsIsFetching = useSelector((appState: AppState) =>
        organizationsFetching(appState),
    );
    const organizationsHasFetched = useSelector((appState: AppState) =>
        organizationsFetched(appState),
    );
    const allOrganizations = useSelector((appState: AppState) =>
        organizations(appState),
    );

    function getOrgs() {
        dispatch(getOrganizations());
    }

    function removeOrg(id: number) {
        dispatch(removeOrganization(id));
    }

    return [
        organizationsIsFetching,
        organizationsHasFetched,
        allOrganizations,
        getOrgs,
        removeOrg,
    ];
}
