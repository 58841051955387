import React from 'react';
import { ContentContainer, IconContainer, ImgWrapped } from './styled';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Image } from '../../../../../../../Objects/types/PhotoGalleryForm';

interface PhotoProps {
    photo: File | Image;
    removeHandler: () => void;
}

export function Photo(props: PhotoProps) {
    function onClickHandle() {
        props.removeHandler();
    }

    return (
        <ContentContainer>
            <ImgWrapped
                src={
                    props.photo instanceof File
                        ? URL.createObjectURL(props.photo)
                        : props.photo.address
                }
            />
            <IconContainer>
                <RemoveCircleIcon onClick={onClickHandle} />
            </IconContainer>
        </ContentContainer>
    );
}
