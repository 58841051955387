import React from 'react';
import { ContentContainer, FileNameContainer, IconContainer } from './styled';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Image } from '../../../../../../../Objects/types/PhotoGalleryForm';

interface FileProps {
    file: File | Image;
    removeDocument: () => void;
}

export function File(props: FileProps) {
    function onClickHandle() {
        props.removeDocument();
    }
    return (
        <ContentContainer>
            <FileNameContainer>
                {(props.file as File).name ??
                    (props.file as Image).originalName}
            </FileNameContainer>
            <IconContainer>
                <RemoveCircleIcon onClick={onClickHandle} />
            </IconContainer>
        </ContentContainer>
    );
}
