import React, { useEffect, useState } from 'react';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
} from '@material-ui/core';

import { Row, HeaderCell, Cell, RowMenu } from './components';
import { Heading } from './types';
import moment from 'moment';
import { SortEnd, SortEvent } from 'react-sortable-hoc';
import { BodyContainer, BodyItem } from '../DndGrid/components';
import { IMenuItem } from './components/RowMenu/RowMenu';

type LocalData<TP> = {
    item: TP;
    values: any[];
}[];

interface DataGridProps<TP> {
    keys?: string[];
    headings: Heading[];
    data: TP[];
    showMenu?: boolean;
    menu?: IMenuItem;
    rowClickHandle?: (args: any) => void;
    cellWidth?: number | undefined;
    sortable?: boolean;
    onEndSortHandle?: (sort: SortEnd, event: SortEvent) => void;
}

function getItemValue(item: any, key?: string | string[]) {
    if (key) {
        if (Array.isArray(key) && key.length) {
            return key.reduce((prev, next) => {
                return prev[next];
            }, item);
        } else {
            return item[key as string];
        }
    }
    return item;
}

export function DataGrid<TP>(props: DataGridProps<TP>) {
    const [data, setData] = useState<LocalData<TP>>([]);
    useEffect(() => {
        setData(mapData(props.data));
        function mapData(data: TP[]): LocalData<TP> {
            return data.map((item: TP, key: number) => {
                return {
                    item,
                    values: props.headings.map(
                        (heading: Heading, index: number) => {
                            let value = getItemValue(item, heading.key);
                            if (heading.type === 'date') {
                                value = moment(value).format(
                                    'DD-MM-YYYY HH:mm',
                                );
                            }
                            return value;
                        },
                    ),
                };
            });
        }
    }, [props]);

    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [sortOrderBy, setSortOrderBy] = useState<any>(null);
    function stringSort(a: any, b: any) {
        if (b === a) {
            return 0;
        }
        if (!b) {
            return 1;
        }
        if (!a) {
            return -1;
        }
        return b?.toUpperCase() < a?.toUpperCase() ? -1 : 1;
    }
    const sortHeader = (index: number) => {
        const heading = props.headings[index];
        const isDesc = sortOrderBy === heading.key && sortOrder === 'desc';
        if (heading.type === 'string') {
            isDesc
                ? data.sort((a, b) =>
                      stringSort(a.values[index], b.values[index]),
                  )
                : data.sort((a, b) =>
                      stringSort(b.values[index], a.values[index]),
                  );
        }
        if (heading.type === 'num') {
            isDesc
                ? data.sort((a, b) => a.values[index] - b.values[index])
                : data.sort((a, b) => b.values[index] - a.values[index]);
        }
        if (heading.type === 'date') {
            isDesc
                ? data.sort(
                      (a, b) =>
                          (moment(a.values[index], 'DD-MM-YYYY') as any) -
                          (moment(b.values[index], 'DD-MM-YYYY') as any),
                  )
                : data.sort(
                      (a, b) =>
                          (moment(b.values[index], 'DD-MM-YYYY') as any) -
                          (moment(a.values[index], 'DD-MM-YYYY') as any),
                  );
        }

        setSortOrder(isDesc ? 'asc' : 'desc');
        setSortOrderBy(heading.key);
    };

    const handleSortStart = ({ node }: any) => {
        const tds = document.getElementsByClassName('sortable-active')[0]
            .childNodes;
        node.childNodes.forEach(
            (node: any, idx: any) =>
                ((tds[idx] as any).style.width = `${node.offsetWidth}px`),
        );
    };

    const renderHeaderRows = () =>
        props.headings.map((heading: Heading, index: number) => (
            <HeaderCell
                active={heading.key === sortOrderBy}
                clickHandler={sortHeader.bind(null, index)}
                key={index}
                direction={sortOrder}
            >
                {/* {props.headings.find(heading => heading.key === key)!.heading} */}
                {heading.heading}
            </HeaderCell>
        ));

    const renderRegularBody = () => (
        <TableBody>
            {data.map((row, index) => (
                <Row onClick={props.rowClickHandle} item={row.item} key={index}>
                    {row.values.map((value, key) => (
                        <Cell width={props.cellWidth} key={key}>
                            {value}
                        </Cell>
                    ))}
                    {props.showMenu ? (
                        <RowMenu
                            menuComponents={(row.item as any).menuComponents}
                        />
                    ) : null}
                </Row>
            ))}
        </TableBody>
    );

    const renderSortableBody = () => (
        <BodyContainer
            helperClass="sortable-active"
            onSortStart={handleSortStart}
            onSortEnd={props.onEndSortHandle}
            useDragHandle
        >
            {data.map((row, index) => (
                <BodyItem
                    index={index}
                    key={index}
                    item={
                        <>
                            {row.values.map((value, key) => (
                                <Cell width={props.cellWidth} key={key}>
                                    {value}
                                </Cell>
                            ))}
                            {props.showMenu ? (
                                <RowMenu
                                    menuComponents={
                                        (row.item as any).menuComponents
                                    }
                                />
                            ) : null}
                        </>
                    }
                />
            ))}
            {/* {data.map((item, index) => (
                <BodyItem index={index} item={item} />
            ))} */}
        </BodyContainer>
    );

    return (
        <Paper>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        {renderHeaderRows()}
                        {props.showMenu ? <Cell>{}</Cell> : null}
                    </TableRow>
                </TableHead>
                {props.onEndSortHandle
                    ? renderSortableBody()
                    : renderRegularBody()}
            </Table>
        </Paper>
    );
}
