import { Step } from '../../../../components/ProgressStepper/types';
import { AddEditGroup } from '../components/AddEditGroup';
import React from 'react';

export const editGroupSteps: Step[] = [
    {
        title: 'Edytowanie',
        // @ts-ignore
        component: <AddEditGroup isEdit={true} />,
    },
];
