import { User } from '../views/Organization/Users/types';

const object = 'object';
export const userTypes = `user/types`;
export const ObjectTypes = `${object}/types`;
export const Templates = 'templates';
export const Schematics = () => `parameter/schemas`;
export const SchematicsById = (schematicId: number) =>
    `parameter/schemas/${schematicId}`;
export const Groups = (organizationId: number) =>
    `organization/${organizationId}/groups`;
export const GroupsById = (organizationId: number, groupId: number) =>
    `organization/${organizationId}/groups/${groupId}`;
export const ObjectById = (objectId: number) => `${object}/${objectId}`;
export const ObjectSettings = (objectId: number) =>
    `${ObjectById(objectId)}/settings`;
export const AddObject = 'objects';
export const UpdateObject = (objectId: number) => `objects/${objectId}`;
export const AddUser = (organizationId: number) =>
    `organization/${organizationId}/users`;
export const EditUser = (user: User) =>
    `organization/${user.organizationId}/users/${user.id}`;
export const DeleteUser = (user: User) =>
    `organization/${user.organizationId}/users/${user.id}`;
const teryt = 'teryt';
export const Voivodeship = `${teryt}/wojewodztwa`;
export const County = (voivodeship: number) =>
    `${teryt}/powiaty/${voivodeship}`;
export const Community = (community: number) => `${teryt}/gminy/${community}`;

export const Organization = 'organization';
export const OrganizationRemove = (id: number) => `${Organization}/${id}`;
export const OrganizationObjects = (organizationId: number) =>
    `${Organization}/${organizationId}/objects`;
export const OrganizationObjectById = (
    organizationId: number,
    objectId: number,
) => `${Organization}/${organizationId}/objects/${objectId}`;
export const OrganizationUsers = (organizationId: number) =>
    `${Organization}/${organizationId}/users`;
export const AddOrganization = `${Organization}/add`;

export const Login = 'login';

export const parameterFormatNumber = 'parameter/format/number';
export const parameterFormatTime = 'parameter/format/time';
export const parameterUnits = 'units';
export const parameterUnitsByTypeId = (typeId: number) => `units/${typeId}`;
export const parameterReadWrite = 'parameter/readWrite';
export const parameterPresentationBinary = 'parameter/presentation/binary';
export const serviceGroups = 'service/groups';
export const parameterPresentationCalculated =
    'parameter/presentation/calculated';
export const parameterVariables = 'parameter/variables';
export const parameterCalculationMethods = 'parameter/calculationMethods';
export const parameterConfigurationTextual = 'parameter/configurations/textual';
export const parameterConfigurationNumerical =
    'parameter/configurations/numeric';
export const alertPriorities = 'alert/priorities';
export const alertChannels = 'alert/channels';
export const appearanceBoxTypes = 'appearance/box/types';
export const appearanceParameterSchemas = 'parameter/schemas';
export const objectIcons = 'objectIcons';
