import { Heading } from '../../../../../../../../components/DataGrid/types';

export const summaryYearDataGridKeys: string[] = ['yearSummary'];
export const summaryYearDataGridHeading: Heading[] = [
    {
        key: 'yearSummary',
        heading: 'SUMA ROCZNIE',
        type: 'string',
    },
];
