import { Heading } from '../../../../components/DataGrid/types';

export const gridKeys = ['name', 'created_at', 'objectsCount'];

export const headings: Heading[] = [
    {
        key: ['name'],
        heading: 'Nazwa',
        type: 'string',
    },
    {
        key: 'created_at',
        heading: 'Data stworzenia',
        type: 'date',
    },
    {
        key: 'objectsCount',
        heading: 'Ilość wykorzystań',
        type: 'num',
    },
];
