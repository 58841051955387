import React from 'react';
import { ContentContainer } from './styled';
import { Photo } from './components';
import { Image } from '../../../../../Objects/types/PhotoGalleryForm';

interface PhotosPanelProps {
    photos: (File | Image)[];
    removeHandler: (index: number) => void;
}

export function PhotosPanel(props: PhotosPanelProps) {
    return (
        <ContentContainer>
            {props.photos.map((photo, index) => (
                <Photo
                    removeHandler={props.removeHandler.bind(null, index)}
                    key={index}
                    photo={photo}
                />
            ))}
        </ContentContainer>
    );
}
