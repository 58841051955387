import React from 'react';
import { DragAndDropZone } from '../DragAndDropZone';
import { ContentContainer } from './styled';
import { useObjectForm } from '../../../Objects/hooks';
import { DocumentsBottomPanel } from './components';
import { BottomNavBar } from '../../../../../components/ProgressStepper/components';
import { Image } from '../../../Objects/types/PhotoGalleryForm';

interface DocumentsProps {
    prevStep: () => void;
    nextStep: () => void;
}

export function Documents(props: DocumentsProps) {
    const [state, setData] = useObjectForm();

    function onDropHandle(file: File[]) {
        setData({ ...state, documents: [...state.documents, ...file] });
    }

    function removeHandle(index: number) {
        const removedDocuments = state.removedDocuments ?? [];
        if (!(state.documents[index] instanceof File)) {
            removedDocuments.push((state.documents[index] as Image).id);
        }
        state.documents.splice(index, 1);
        setData({
            ...state,
            documents: state.documents,
            removedDocuments,
        });
    }

    return (
        <ContentContainer>
            <DragAndDropZone
                changeHandle={onDropHandle}
                acceptFiles={['.pdf', '.xlsx', '.xls', '.csv']}
            />
            <DocumentsBottomPanel
                removeDocument={removeHandle}
                files={state.documents}
            />
            <BottomNavBar
                prevStep={props.prevStep}
                showBack={true}
                nextStep={props.nextStep}
                buttonNextText="Dalej"
            />
        </ContentContainer>
    );
}
