import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducer';
import {
    addSchematicForm,
    schematicAddingPending,
    schematicEditionPending,
    schematicHasAdded,
    schematicHasEdited,
} from '../selectors/schematics';
import {
    addSchematic,
    editSchematic,
    getSchematics,
    hydrateEditForm,
    removeSchematicAction,
    resetStore,
} from '../actions/schematics';
import { Schematic } from '../types/Schematics';
import { SchematicForm } from '../types/SchematicForm';

interface useAddEditSchematicOutput {
    schematicIsAdding: boolean;
    schematicHasEditedState: boolean;
    schematicEditionIsPending: boolean;
    newSchematicHasAdded: boolean;
    getSchematicsHandle: () => void;
    resetStoreHandle: () => void;
    addNewSchematic: (schematic: SchematicForm) => void;
    removeSchematic: (schematic: Schematic) => void;
    editSchematicHandle: (schematic: SchematicForm) => void;
    hydrateForm: (schematic: SchematicForm) => void;
    stateAddNewSchematic: SchematicForm;
}

export function useAddEditSchematic(
    organizationId: number,
): useAddEditSchematicOutput {
    const dispatch = useDispatch();

    const schematicIsAdding = useSelector((state: AppState) =>
        schematicAddingPending(state),
    );
    const schematicHasEditedState = useSelector((state: AppState) =>
        schematicHasEdited(state),
    );
    const schematicEditionIsPending = useSelector((state: AppState) =>
        schematicEditionPending(state),
    );

    const newSchematicHasAdded = useSelector((state: AppState) =>
        schematicHasAdded(state),
    );
    const stateAddNewSchematic = useSelector((state: AppState) =>
        addSchematicForm(state),
    );

    function addNewSchematic(schematic: SchematicForm) {
        dispatch(addSchematic(schematic, organizationId));
    }

    async function removeSchematic(schematic: Schematic) {
        await removeSchematicAction(organizationId, schematic);
        dispatch(getSchematics(organizationId));
    }

    function editSchematicHandle(schematic: SchematicForm) {
        dispatch(editSchematic(schematic, organizationId));
    }

    function hydrateForm(schematic: SchematicForm) {
        return dispatch(hydrateEditForm(schematic));
    }

    function getSchematicsHandle() {
        dispatch(getSchematics(organizationId));
    }

    function resetStoreHandle() {
        dispatch(resetStore());
    }

    return {
        schematicIsAdding,
        getSchematicsHandle,
        newSchematicHasAdded,
        schematicHasEditedState,
        schematicEditionIsPending,
        addNewSchematic,
        removeSchematic,
        editSchematicHandle,
        resetStoreHandle,
        hydrateForm,
        stateAddNewSchematic,
    };
}
